import { SET_DATE } from "../actions/date";

export const DATES = [
    {
        value : 'Today',
        date : {
            start : /* Start of the day from 00.00 */  new Date(),
            end : /* End of the day at 23.59 */ new Date()
        }
    },
    {
        value : 'Yesterday',
        date : {
            start : /* Start of the day from 00.00 */  new Date(),
            end : /* End of the day at 23.59 */ new Date()
        }
    },
    {
        value : 'Last 7 Days',
        date : {
            start : /* Start of the day from 00.00 */  new Date(),
            end : /* End of the day at 23.59 */ new Date()
        }
    },
    {
        value : 'Last 30 Days',
        date : {
            start : /* Start of the day from 00.00 */  new Date(),
            end : /* End of the day at 23.59 */ new Date()
        }
    }
]

const initialState = DATES[0];

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_DATE : {     
             return{...action.action}
        }
      default:
        return state;
    }
  }
  




