    
export const billing = {
    firstName : '',
    lastName : '',
    phoneNumber : '',
    companyName : '',
    vatNumber : '',
    vat : '',
    email : '',
    city : '',
    country : '',
    postalCode : '',
    street : '',
    houseNumber : '',
    phoneNumber : '',
    clientType: ''
};

export const delivery = {
    deliveryAddress : '',
    deliveryMethod : '',
    city : '',
    country : '',
    postalCode : '',
    firstName : '',
    lastName : '',
    phoneNumber : '',
    email : '',
    postalCode : '',
}

export const deliveryMethods = (t) => {
    const deliveryMethods = {};
    deliveryMethods[t('pick_up')] = 1;
    deliveryMethods[t('delivery')] = 2;
    return new Map(Object.entries(deliveryMethods));
};