import { MOBILE_WIDTH } from "../app";

export const isMobile = (window) => {
    return window.innerWidth < MOBILE_WIDTH;
}

export const isNumber = (str) => {
    return !isNaN(Number(str)) && str.trim() !== '';
}

export const PROMOTION_STORAGE_KEY = 'showPromotion';