  
export const SET_APP = 'SET_APP';

export function setApp(data) {
    return {
      type: SET_APP,
      action : data
    };
}
  
  