import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Import translation files
import enTranslation from './locales/en.json';
import ptTranslation from './locales/pt.json';

const resources = {
    en: {
      translation: enTranslation,
      name: "English"
    },
    pt: {
      translation: ptTranslation,
      name: "Português"
    },
  }
;

// Configuration
i18n
  .use(LanguageDetector) 
  .use(initReactI18next) 
  .init({
    resources: resources,
    fallbackLng: 'pt', // Set default fallback language
    debug: true,
    interpolation: {
      escapeValue: false, // React already escapes values
    },
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
      caches: ['localStorage', 'cookie'],
    },
  });

export const availableLanguages = resources;
export const defaultLanguage = "Português";
export const defaultLanguageKey = "pt";
export default i18n;