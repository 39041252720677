/* Create Brand Picker Modal */
/* Should be able to select a brand from a list of brands */
/* Should be able to search for a brand */
/* Should be able to create a new brand */

import { connect } from "react-redux";
import Modal from "..";
import "./index.scss";
import 'react-calendar/dist/Calendar.css';
import Image from "../../Image";
import { loadingIcon, logo } from "../../../../assets/images";
import { useTranslation, withTranslation } from "react-i18next";
import ResgisterForm from "../../ResgisterForm";
import { useState } from "react";
import CacheSingleton from "../../../lib/Cache";
import { PROMOTION_STORAGE_KEY } from "../../../../utils/utils";


const NewUserPromotionModal = (props) => {
    const { t, i18n } = useTranslation();
    const { user } = props;
    if((user && Object.keys(user).length > 0) || CacheSingleton.get(PROMOTION_STORAGE_KEY) == 'false'){
        CacheSingleton.save(PROMOTION_STORAGE_KEY,false);
        return <></>;
    }

    if(CacheSingleton.get(PROMOTION_STORAGE_KEY) === null || CacheSingleton.get(PROMOTION_STORAGE_KEY) === undefined){
        CacheSingleton.save(PROMOTION_STORAGE_KEY, true);
    }
    return (
        <Modal keyItem='NewUserPromotion' middle={true} onClose={() => CacheSingleton.save(PROMOTION_STORAGE_KEY, false)}>
            <div className="flex flex-col items-center new-user-promotion-modal-container">
                <div className="stack  new-user-promotion-modal-stack-container">
                    <div className="new-user-promotion-container center">
                        <Image src={loadingIcon} className="new-user-promotion-image" />
                    </div>
                    <div className="flex flex-col items-center z-50">
                        <h4>
                            {t('new_user_modal_1')}
                        </h4>
                        <h1>
                            100€
                        </h1>
                        <h4>
                            {t('new_user_modal_2')}
                        </h4>
                    </div>
                </div>
                <ResgisterForm promotion={true}/>
            </div>
        </Modal>
    )
}

function mapStateToProps(state) {
    return {
        user : state.user,
    };
}

export default connect(mapStateToProps)(NewUserPromotionModal);