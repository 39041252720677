import './index.scss';
import { Component, useEffect, useState } from 'react';
import ConnectionAPI from '../../api';
import { useTranslation } from 'react-i18next';
import CardCompact from '../../components/Card/Compact';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { isMobile } from '../../../utils/utils';
import ContainedLoading from '../ContainedLoading';


const PromotedCarsPage = (props) => {
    const [promotedCars, setPromotedCars] = useState(null);
    const [displayingPromotedCars, setDisplayingPromotedCars] = useState(null);
    const [displayingStartingIndex, setDisplayingStartingIndex] = useState(0);
    const { t, i18n } = useTranslation();

    const mobile = isMobile(window);

    useEffect(() => {
        async function getPromotedCars() {
            let res = await ConnectionAPI.getPromotedCars();
            const cars = res.message.result.cars;
            setPromotedCars(cars);

            if(mobile){
                setDisplayingPromotedCars(cars.slice(0,1));
            }else{
                setDisplayingPromotedCars(cars.slice(0,3));
            }            
        };

        getPromotedCars();
      }, []);

    const renderButton = (isLeft) => {
        const canSlide = (promotedCars && promotedCars.length > 3) || (mobile && promotedCars.length > 1);
        return (
            <div className={`action-button ${canSlide ? '' : 'opacity-50 hover:!cursor-default'} `} onClick={() => {
                    if(!canSlide)
                        return;
                    let start = displayingStartingIndex;
                    if(isLeft){
                        start--;
                    }else{
                        start++;
                    }

                    if(start < 0){
                        start = promotedCars.length - 1;
                    }
                    if(start >= promotedCars.length){
                        start = 0;
                    }
                    let newDisplay = [];
                    for(let i = start; i <= start + (mobile ? 0 : 2); i++){
                        if(i >= promotedCars.length)
                            newDisplay.push(promotedCars[i%promotedCars.length]);
                        else
                            newDisplay.push(promotedCars[i]);

                    }

                    setDisplayingPromotedCars(newDisplay);
                    setDisplayingStartingIndex(start);
                    console.log(displayingStartingIndex);
                }
            }>
                {
                    isLeft ?
                    <ArrowBack className='icon'/>
                    :
                    <ArrowForward className='icon'/>
                }
            </div>
        )
    }

    if(!displayingPromotedCars || displayingPromotedCars.length == 0)
        return <></>
    return (
        <div className="box-background align-middle">
            <h2 className='center pt-10'>{t('promoted_1')}</h2>
            {
                !displayingPromotedCars || displayingPromotedCars.length == 0 
                ?
                <ContainedLoading transparentBackground={true}/>
                :
                <div className='promoted-grid'>
                    {renderButton(true)}
                    <div className='promotion-container'>
                        <div className={`${mobile ? 'block' : 'promoted-cars-grid'}`}>
                            {
                            displayingPromotedCars ? 
                            displayingPromotedCars.map( (item, index)  => (
                                    <CardCompact key={index} car={item} />
                                ))
                            :<></>
                            }
                        </div>            
                    </div>
                    {renderButton(false)}
                </div>
            }
        </div>
    )
  
}

export default PromotedCarsPage;