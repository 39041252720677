import queryString from 'query-string';
import store from '../redux/store';
import { setRoute } from '../redux/actions/route';


class UrlNavigator{

    __toPage = (relativeURL) => {
        document.location.href = relativeURL;
    }
    
    toApp = () => {
        this.__toPage(`/`)
    }

    getPackId = () => {
        const parsed = queryString.parse(document.location.search);
        return parsed.pack_id;
    }

    getSearchQuery = () => {
        const parsed = queryString.parse(document.location.search);
        return parsed.search;
    }

    getFields = (fields) => {
        const parsed = queryString.parse(document.location.search);
        let res = {};
        fields.forEach((field) => {
            res[field] = parsed[field];
        })
        return res;
    }

    toPackIdPage = (packId) => {
        this.__toPage(`/app/pack?pack_id=${packId}`)
    }

    isInPage = (string) => {
        let url = new URL(window.location.href);
        return (url.pathname.indexOf(string) != -1);
    }

}



const UrlNavigatorSingleton = new UrlNavigator();

export default UrlNavigatorSingleton;