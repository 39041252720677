import './index.scss';
import React, {useEffect, useState} from 'react';
import Loading from '../../components/Loading';
import { filtersSearch, getFilterValues } from '../../../filters/search';
import { formSearch, getStoredCarFilters, setStoredCarFilters } from '../../../forms/search';
import Button from '../../components/Button';
import ConnectionAPI from '../../api';
import { getObjectFromQueryString, getQueryStringFromObject } from '../../lib/Helpers';
import { renderItemList,renderDualOptionItem } from '../../lib/Helpers';
import { scrollToTopPage } from '../../pages/Utils/utils';
import { useTranslation } from 'react-i18next';
import { isMobile } from '../../../utils/utils';
import { ArrowBackIos, DeleteOutline } from '@mui/icons-material';
import ContainedLoading from '../../components/ContainedLoading';
import { mainFormSearch } from '../../../forms/mainsearch';
import { circleCross, filterIcon, hamburgerMenu } from '../../../assets/images';

const FilterView = (props) => {
    const [form, setForm] = useState(mainFormSearch());
    const [offers, setOffers] = useState('');
    const [changedForm, setChangedForm] = useState(false); //used because mobile has a button to search the filters
    const [changedCount, setChangedCount] = useState(false); //for mobile
    const [loadingCount, setLoadingCount] = useState(false); //for mobile
    const [firstLoad, setFirstLoad] = useState(true);
    const [oldColor, setOldColor] = useState(null);
    const [allFilters, setAllFilters] = useState(null);
    const { t, i18n } = useTranslation();
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);
    const mobile = isMobile(window);

    const emptyDropDown = (subType) => {
        if(subType == 'make'){
            form.filters.model = '';
        }

        if(subType == 'registrationFrom'){
            updateDependencyValues(null,'registrationFrom','registrationTo');
        }else if(subType == 'priceFrom'){
            updateDependencyValues(null,'priceFrom','priceTo');
        }else if(subType == 'mileageFrom'){
            updateDependencyValues(null,'mileageFrom','mileageTo');
        }else if(subType == 'powerFrom'){
            updateDependencyValues(null,'powerFrom','powerTo');
        }

        setForm({...form, filters : {...form.filters, [subType] : ''}});

        if(mobile)
            setChangedCount(!changedCount);
        else
            setChangedForm(!changedForm);
    };


    /* Run on ComponentDidMount */
    useEffect(() => {
        if(!mobile)
            setIsMenuOpen(true);

        async function setFiltersOptions() {
            const filters = await filtersSearch(t,i18n.language,form.filters.make);
            if(allFilters != null){
                filters.registrationTo = allFilters.registrationTo;
                filters.priceTo = allFilters.priceTo;
                filters.mileageTo = allFilters.mileageTo;
                filters.powerTo = allFilters.powerTo;
            }
            
            setAllFilters(filters);
        };
        
        if(firstLoad && !window.location.search){
            let aux = getStoredCarFilters();
            if(aux == undefined)
                aux = formSearch;

            for(let key in aux.filters){
                form.filters[key] = aux.filters[key];
            }
            setForm(form);
            setFirstLoad(false);
        }else if(firstLoad && window.location.search){
            //reset filters
            for(let key in formSearch.filters){
                form.filters[key] = formSearch.filters[key];
            }
            /* Get all filters */
            /* Access Form State coming from Query */
            const filters = getObjectFromQueryString({queryString : window.location.search});

            let newUrl = new URL(window.location);
            newUrl.search = '';
            window.history.replaceState(null,'', newUrl);
            
            /* Add values present in filters to formDefault */
            for(let key in filters){
                form.filters[key] = filters[key];
            }
            /* Set Form State */
            setForm({...form, filters : {...form.filters, ...filters}});
            setFirstLoad(false);
        }

        setFiltersOptions();

        //change filters
        async function setFilters() {
            setStoredCarFilters(form.filters);
            props.changeVehicleFilter(await getFilterValues(t,i18n.language,form.filters));
        }; 
        setFilters();

        if(mobile){
            setChangedCount(!changedCount);
            setIsMenuOpen(false);
        }

    }, [mobile,changedForm]);

    useEffect(() => {
        if(mobile){
            async function getOffers() {
                let res = await ConnectionAPI.getNumberOfOffers(await getFilterValues(t,i18n.language,form.filters));
                setOffers(res.message.length);
            };

            setLoadingCount(true);
            if(props.initialCount == -1){
                setOffers(props.initialCount);
                props.initialCount = -1;
            }else
                getOffers();
            setLoadingCount(false);
        }
    }, [changedCount]);
    
    const updateDependencyValues = async (value,parent,dependent) => {
        const newValues = []
        const allowedValues = allFilters[parent];
        for(let i = 0; i < allowedValues.length; i++){
            if(value == null || allowedValues[i] >= value){
                newValues.push(allowedValues[i]);
            }
        }

        const newAllFilters  = allFilters;
        newAllFilters[dependent] = newValues;
        setAllFilters(newAllFilters);
    }

    const onChangeDependent = async (key, value, parentField, options={variable : false, array : false}) => {
        let dependent = '';
        let updateDependencyValueList = true;
        switch(key){
            case 'registrationFrom':
                dependent='registrationTo';
                break;
            case 'priceFrom':
                dependent='priceTo';
                break;
            case 'priceTo':
                dependent='priceFrom';
                updateDependencyValueList = false;
                break;
            case 'mileageFrom':
                dependent='mileageTo';
                break;
            case 'mileageTo':
                dependent='mileageFrom';
                break;
            case 'powerFrom':
                dependent='powerTo';
                break;
            case 'powerTo':
                dependent='powerFrom';
                updateDependencyValueList = false;
                break;
        }

        if((form[parentField][dependent] < value && updateDependencyValueList) || (form[parentField][dependent] > value && !updateDependencyValueList)){
            form.filters[dependent] = '';
            setForm(form);
        }
        
        if(updateDependencyValueList)
            updateDependencyValues(value,key,dependent);

        onChange(key,value,parentField,options);
    }
    
    const onChange = (key, value, parentField, options={variable : false, array : false}) => {
        if(key == 'make' && form.filters[key] != value){
            form.filters.model = '';
        }

        if(options.array){
            if(options.variable){
                const array = this.state.form[parentField][key];
                if(array.includes(value)){
                    const index = array.indexOf(value);
                    array.splice(index, 1);
                }else{
                    array.push(value);
                }
                setForm({...form, [parentField] : {...[parentField], [key] : array}});
            }else{
                setForm({...form, [parentField] : {...form[parentField], [key] : [value]}});
            }
        }else{
            setForm({...form, [parentField] : {...form[parentField], [key] : value}});
        }

        if(!mobile){
            setChangedForm(!changedForm);
        }else{
            setChangedCount(!changedCount);
        }

    }

    const renderColors = () => {

        return (
            <div className=''>
                <h5 className='left-text bold-text title' style={{marginBottom : 5}}>{t('exterior_colors')}</h5>
                <div className='small-container'></div>
                {allFilters.color.map((color, index) => 
                {
                    let isDefault = color == "Default";
                    const isActive = form.filters.color == color;
                    if(!isDefault){
                        return (
                            <div key={index} onClick={
                            () => {
                                if(oldColor == color){
                                    //deselect the currently selected color since the same colour was picked
                                    color = null;
                                }
                                onChange('color', color, 'filters', {variable : false, array : false});
                                setOldColor(color);
                            }
                            } className={`image-color ${isActive ? 'isActive' : ''}`} style={{backgroundColor : color}}/>
                        )
                    }else{
                        return (
                            <div key={index} onClick={
                                () => {
                                    color = null;
                                    onChange('color', color, 'filters', {variable : false, array : false});
                                    setOldColor(color);
                                }
                                } className={`image-color ${isActive ? 'isActive' : ''}`}>
                                <img src={circleCross}/>
                            </div>
                        )
                    }
                }
                )}
            </div>
            
        )
    }

    const resetFilters = () => {
        setForm(mainFormSearch());
        scrollToTopPage();
        setChangedForm(!changedForm);
    }

    const showMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    }

    const filtersHTML =  
        isMenuOpen && allFilters ? 
        <>
            <div className='divider'></div>
            <div className='padding filters' style={{paddingTop : 0}}>
                {renderItemList({title : t('make'), keyItem : 'make', index : 1, placeholder : t('select'), parentField : 'filters',
                    allFilters: allFilters,emptyDropDown: emptyDropDown,form:form,onChange: onChange,search: true
                })}
                {renderItemList({allFilters: allFilters,emptyDropDown: emptyDropDown,form:form,onChange: onChange,title : t('model'), keyItem : 'model'
                    , index : 1, placeholder : t('select'), parentField : 'filters', search: true})}
                <h5 className='bold-text'>{t('registration')}</h5>
                <div className='grid-50'>
                    {renderItemList({allFilters: allFilters,emptyDropDown: emptyDropDown,form:form,onChange: onChangeDependent,
                        keyItem : 'registrationFrom', index : 1, placeholder : t('from'), parentField : 'filters', search: true})}
                    {renderItemList({allFilters: allFilters,emptyDropDown: emptyDropDown,form:form,onChange: onChange,
                        keyItem : 'registrationTo', index : 1, placeholder : t('to'), parentField : 'filters', search: true})}
                </div>
                <h5 className='bold-text'>{t('price')} (EUR)</h5>
                <div className='grid-50'>
                    {renderItemList({allFilters: allFilters,emptyDropDown: emptyDropDown,form:form,onChange: onChangeDependent,
                        keyItem : 'priceFrom', index : 1, placeholder : t('from'), parentField : 'filters', search: true
                        ,allowNewOption: true})}
                    {renderItemList({allFilters: allFilters,emptyDropDown: emptyDropDown,form:form,onChange: onChange,
                        keyItem : 'priceTo', index : 1, placeholder : t('to'), parentField : 'filters', search: true
                        ,allowNewOption: true})}
                </div>
                <h5 className='bold-text'>{t('mileage')}</h5>
                <div className='grid-50'>
                    {renderItemList({allFilters: allFilters,emptyDropDown: emptyDropDown,form:form,onChange: onChangeDependent,keyItem : 'mileageFrom', index : 1, placeholder : t('from')
                        , parentField : 'filters', search: true,allowNewOption: true})}
                    {renderItemList({allFilters: allFilters,emptyDropDown: emptyDropDown,form:form,onChange: onChange,keyItem : 'mileageTo', index : 1, placeholder : t('to')
                        , parentField : 'filters', search: true,allowNewOption: true})}
                </div>
                {renderDualOptionItem({onChange:onChange,title :  t('transmission'), placeholder : t('select'), form:form,
                options : allFilters.transmission, keyItem : 'transmission', parentField : 'filters',canDeselect : true})}
                {renderItemList({allFilters: allFilters,emptyDropDown: emptyDropDown,form:form,onChange: onChange,title : t('fuel'), keyItem : 'fuel', index : 3, placeholder : t('select'), parentField : 'filters'})}
                {renderDualOptionItem({form:form,onChange:onChange,title : t('power')
                    , placeholder : t('select'), options : Object.keys(allFilters.powerUnit)
                    , keyItem : 'powerUnit', parentField : 'filters',canDeselect : true})}
                <div className='grid-50'>
                    {renderItemList({allowNewOption:true, allFilters: allFilters,emptyDropDown: emptyDropDown,form:form,onChange: onChangeDependent,keyItem : 'powerFrom', index : 1, placeholder :  t('from'), parentField : 'filters', search: true})}
                    {renderItemList({allowNewOption:true, allFilters: allFilters,emptyDropDown: emptyDropDown,form:form,onChange: onChangeDependent,keyItem : 'powerTo', index : 1, placeholder :  t('to'), parentField : 'filters', search: true})}
                </div>
                {/* {renderItemList({allFilters: allFilters,emptyDropDown: emptyDropDown,form:form,onChange: onChange,title : t('vehicle_type'), keyItem : 'vehicleType', index : 2, placeholder :  t('select'), parentField : 'filters'})} */}
                {/* Colors */}
                {renderColors()}
            </div>
            {mobile ?
                <Button className={`button`} onClick={() => 
                                    {
                                        setChangedForm(!changedForm);
                                        setIsMenuOpen(false);
                                    }}>
                    {
                        loadingCount ? 
                        <Loading/>
                        :
                        <h4>{offers + ` ${t('offers')}`}</h4>
                    }
                </Button>
                
            :
            <></>
            }
        </>
        :
        <></>;

    const filterButtonClick = () => {
        if(mobile)
            showMenu();
    }

    return (
        <>{!allFilters ? <ContainedLoading /> : 
            <>
            {mobile ? 
                <div className={`${isMenuOpen ? 'filter-container-open' : 'filter-container-close'}`}>
                    <div className='flex justify-between'>
                        <div>
                            <ArrowBackIos className='inline' onClick={() => showMenu()}/>
                            <h4 className='inline'>{t('filter')}</h4> 
                        </div>
                        <DeleteOutline className='!h-8 !w-8 hover:cursor-pointer' onClick={() => resetFilters()} />
                    </div>
                    {filtersHTML}
                </div>
                :
                <></>
            }
            <div className={`small-container ${mobile && !isMenuOpen ? 'filter-container-closed' : ''}`}>  
                <div className='box-container no-padding filter-button' onClick={() => filterButtonClick()}>
                    {
                        !isMenuOpen || !mobile ?
                        <div>
                            {mobile ?
                                <div className={`center !mb-0`}>
                                    <img className='inline asas' src={hamburgerMenu} width={32} height={32} />
                                    <h4 className={`title inline`} style={{paddingBottom : 0}}>{t('filter')}</h4>
                                </div>
                            :
                            <div className='!mb-0 flex justify-between padding !pb-0'>
                                <h4 className='title' style={{paddingBottom : 0}}>{t('filter')}</h4>
                                <DeleteOutline className='!h-8 !w-8 hover:cursor-pointer' onClick={() => resetFilters()} />
                            </div>
                            }
                        </div>
                        :
                        <></>
                    }
                    {!mobile ? filtersHTML : <></>}           
                </div>
            </div>
            </>
            }
        </>
    )

    
}

export default FilterView;