import React, { useState, useEffect, StrictMode } from 'react';
import '../index.scss'; // Ensure your CSS variables are defined here or in an imported file
import store from './redux/store'; // Your Redux store
import { createTheme, ThemeProvider } from '@mui/material';
import { connect } from 'react-redux';
import { SkeletonTheme } from 'react-loading-skeleton';
import { getCSSVariableValue } from './lib/Helpers';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import CustomSwitch from './components/CustomSwitch';
import { ROUTES } from './route';
import { FACEBOOK_PIXEL, INTERCOM_APP_ID, INTERCOM_SECRET } from '../config';
import ReactPixel from 'react-facebook-pixel';
import { IntercomProvider } from 'react-use-intercom';
import CryptoJS from 'crypto-js';
import Intercom from '@intercom/messenger-js-sdk';


const RouteIndex = () => {
    return (
        <BrowserRouter>
            <CustomSwitch>
                {ROUTES.map(route => {
                  //pageview();
                  if (route.children) {
                        return (
                            <Route path={route.path} element={route.page} key={route.key}>
                                {route.children.map(subRoute => (
                                    <Route path={subRoute.path} element={subRoute.page} key={subRoute.key} />
                                ))}
                            </Route>
                        );
                    } else {
                        return <Route path={route.path} element={route.page} key={route.key} />;
                    }
                })}
            </CustomSwitch>
        </BrowserRouter>
    );
}


const App = () => {
    const [muiTheme, setMuiTheme] = useState(createTheme());
    useEffect(() => {
      // Dynamically create the MUI theme
      const dynamicMuiTheme = createTheme({
      background: {
        default: getCSSVariableValue('--primary-color')
      },
      palette: {
        mode: getCSSVariableValue('--color-mode')
      }
      });
  
      setMuiTheme(dynamicMuiTheme);
  
      // Initialize Facebook Pixel
      ReactPixel.init(FACEBOOK_PIXEL);
      ReactPixel.pageView();
    }, []); 
    
    const currentUser = store.getState().user;
    if(currentUser != null && Object.keys(currentUser).length !== 0){
        const secretKey = INTERCOM_SECRET;
        const userIdentifier = currentUser.id.toString();
        const hash = CryptoJS.HmacSHA256(userIdentifier, secretKey).toString(CryptoJS.enc.Hex);

        Intercom({
            app_id: INTERCOM_APP_ID,
            user_id: currentUser.id, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
            name: currentUser.firstName, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
            email: currentUser.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
            created_at: currentUser.createdAt, // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
            user_hash:hash,
          });       
            return (
                <SkeletonTheme>
                    <ThemeProvider theme={muiTheme}>
                      <RouteIndex />
                    </ThemeProvider>
                </SkeletonTheme>
          );
      }
      // <IntercomProvider autoBoot={true} appId={INTERCOM_APP_ID}>
      return (
        <SkeletonTheme>
            <ThemeProvider theme={muiTheme}>
                <RouteIndex />
            </ThemeProvider>
        </SkeletonTheme>
      );
}
  
function mapStateToProps(state) {
    return {
        user :state.user
    };
}
  
export default connect(mapStateToProps)(App);