/* Create Hero Section with Image and Text */
import React from 'react';
import './index.scss';
import ReactStars from 'react-stars'
import { useTranslation } from 'react-i18next';
import { isMobile } from '../../../utils/utils';

const ReviewsHero = ({rating}) => {
    const { t, i18n } = useTranslation();
    const mobile = isMobile(window);
    return (
        <div>
            <div className='section box-background'>
                <div className={`container ${mobile ? '' : 'grid-50'} `}>
                    <div className='small-container'>
                        <h4 className={`primary-text ${mobile ? 'text-center' : ''}`}>Versicar {t('review_1')}</h4>
                        <h2 className={`${mobile ? 'text-center' : ''}`}>{t('review_2')}</h2>
                    </div>
                    <div className='small-container box-container'>
                        <div className='center'>
                            <div className='inline'>
                                <ReactStars
                                    count={5}
                                    value={rating}
                                    edit={false}
                                    size={40}
                                    color2={'#ffd700'}
                                />
                                <h1 className='center inline' style={{ marginLeft: 15 }}>{rating} <span>/5</span></h1>
                            </div>
                        </div>
                        {/* <h5 className='center grey'>1000 {t('review_1')}</h5> */}
                    </div>
                </div>
            </div>
        </div>

    )
}

export default ReviewsHero;