import './index.scss';
import { Component } from 'react';
import { connect } from 'react-redux';

class MultiOptionSelector extends Component{
    constructor(props){super(props);this.state = {loading : false}}
    
    componentDidMount(){}    
    
    onClick = (item) => {
        const { onChange, keyItem, parentField , variable } = this.props;
        return onChange(keyItem, item, parentField, {variable : variable, array : true});
    
    }
    
    render = () => {
        const { options, title, variable, form, parentField, keyItem, isRequired } = this.props;
        /* Variable means that the width of the box is variable */
        return (
            <div className={`box-container no-border no-padding`} style={{marginTop : 16}}>
                <span className='inline'>
                    <h5 className={'title bold-text'}>{title}</h5>
                    {isRequired ? <h5 className='!text-red-600 !pt-0 !pb-0 !pl-1 !pr-0'>*</h5> : null}
                </span>                  <div className={`${variable ? 'container-inline' : ''} multi-option-container`}>
                    {options.map((item, index) => 
                    {   
                        const isSelected = form[parentField][keyItem].includes(item);
                        return (
                            <div onClick={() => this.onClick(item)} key={index} className={`${isSelected ? 'isActive' : ''} box-click box-container no-background inline margin no-padding center-text ${variable ? 'fit-box' : ''}`}>
                                <h5 className='center-text '>{item}</h5>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }
    
}

function mapStateToProps(state) {
    const user = state.user;
    return {
        user
    };
  }

  export default connect(mapStateToProps)(MultiOptionSelector);
