import { Component } from 'react';
import { connect } from 'react-redux';
import './index.scss';
import { MetaTags } from 'react-meta-tags';
import ConnectionAPI from '../../../api';
import CarListVertical from '../../../sections/CarsList/Vertical';
import { withTranslation } from 'react-i18next';

const pageSize = 10;

class FavoritesPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cars: [],
            loading: false,
            currentPage: 1,
            totalPages: 0
        };
    }

    componentDidMount() {
        const { user } = this.props;
        if (!user.id) { return null; }
        this.getFavorites({ page: this.state.currentPage });
    }

    getFavorites = async ({ page }) => {
        this.setState({ loading: true });
        const { user } = this.props;
        const response = await ConnectionAPI.getFavorites();
        if (response.status === 200) {
            const { cars } = response.message;
            const totalPages = Math.ceil(cars.length / pageSize);
            const paginatedCars = cars.slice((page - 1) * pageSize, page * pageSize);
            this.setState({ cars: paginatedCars, loading: false, currentPage: page, totalPages, total: cars.length });
        } else {
            this.setState({ loading: false });
        }
    }

    onChangePage = (event, page) => {
        this.getFavorites({ page });
    }

    render() {
        const { user, t } = this.props;
        const { cars, loading, currentPage, totalPages, total } = this.state;
        if (!user.id) { return null; }

        return (
            <div className='page'>
                <MetaTags>
                    <title>Favorites</title>
                    <meta name="description" content="Manage your Account" />
                    <meta property="og:title" content="Account" />
                </MetaTags>
                <div className='container' style={{ marginTop: 0 }}>
                    <CarListVertical
                        title={t('favorite_1')}
                        total={total}
                        cars={cars}
                        loading={loading}
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onChangePage={this.onChangePage}
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const user = state.user;
    return { user };
}

export default connect(mapStateToProps)(withTranslation()(FavoritesPage));