import { Component } from 'react';
import { connect } from 'react-redux';
import './index.scss'

class CheckBox extends Component{
    constructor(props){
        super(props)
        this.state = {
            checked : false
        }
    }

	handleChange = (e) => {
        this.props.handleChange(e);
	  };

    
    render = () => {
        const { filters, keyItem, valueItem, index, name, amount } = this.props;
        var isChecked = false;
        if(filters && filters[keyItem].length){
            isChecked = filters[keyItem].includes(valueItem);
        }
        return (
            <div key={index} className='selectorMultiSelect'>
                <div className='inline align'>
                    <input checked={isChecked} onChange={() => {}} onClick={this.handleChange} type='checkbox'>
                    </input>
                </div>
                <div className='inline align' style={{marginLeft : 5}}>
                    <h5 className='inline'>{name}</h5>
                    <h5 className='inline text-grey'>{amount}</h5>
                </div>
            </div>
			
        )
    }
    
}

function mapStateToProps(state) {
    const user = state.user;
    return {
        user
    };
  }
  
  export default connect(mapStateToProps)(CheckBox);
