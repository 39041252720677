import './index.scss';
import { Component } from 'react';
import { connect } from 'react-redux';
import Loading from '../../components/Loading';
import { MetaTags } from 'react-meta-tags';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

class ThankYouPage extends Component{
    constructor(props){super(props);this.state = {loading : false}}
    
    componentDidMount(){

    }    

    render = () => {
        const { loading } = this.state;
        const { t } = this.props;
 
        return (
            <>  
                <>{ (loading) ? <Loading/> : null}</>
                <MetaTags>
                    <title> Versicar </title>
                    <meta name="description" content="Buy your best car" />
                    <meta property="og:title" content="Versicar" />
                </MetaTags>
                {/* Header */}
                <Header/> 
                {/* Main Container */}
                <div className='center' style={{height : 500}}>
                    <div className='container section box-background' style={{height : 200}}>
                        <h1 className='center'>{t('thank_you_1')} 🎉</h1>
                        <Link to='/app/orders' className='button fit center'> 
                            <h3>{t('thank_you_2')}</h3>                    
                        </Link>
                    </div>
                </div>
                
                {/* Footer */}
                <Footer/>
            </>
            )
    }
    
}

function mapStateToProps(state) {
    const user = state.user;
    return {
        user
    };
  }

export default connect(mapStateToProps)(withTranslation()(ThankYouPage));