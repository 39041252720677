import './index.scss';
import { connect } from 'react-redux';
import LeftBar from '../../components/LeftBar';
import UserAPI from '../../lib/User';
import { Outlet, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import NotificationTab from '../../components/NotificationTab';
import delay from 'delay';
import Loading from '../../components/Loading';
import Header from '../../components/Header';

const AppPage = (props) => {
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [tab, setTab] = useState(0);


    const auth = async () => {
        // Add Loading Bar and wait for users after subscription to update DB
        await delay(0.5*1000);
        // Verify if User Exists in Cache
        let isAuth = await UserAPI.auth();
        if(!isAuth){
            // Go to Signup Page
            navigate("/signup")
        }else{
            await UserAPI.start();
            setLoaded(true)
        }
    }

    const handleClicksToCloseModal = () => {
        window.addEventListener('click', function(e){  
            try{
                const parentDivIsModal = e.target.closest('.modal');
                const parentDivIsContainer = e.target.closest('.modal-selector');
                if (parentDivIsModal || parentDivIsContainer) {
                    // Clicked inside modal or container
                    // Close all other modals
                    const modals = document.querySelectorAll('.showModal');
                    const parent = parentDivIsModal ? parentDivIsModal : parentDivIsContainer;
                    modals.forEach(modal => {
                        const hasChildWithId = 
                            (parent.querySelector(`#${modal.id}`) !== null) 
                            || (parent.id == modal.id);
                        if (!hasChildWithId) {
                            modal.classList.replace('showModal','hideModal');
                        }else{
                            modal.classList.replace('hideModal','showModal');
                        }
                    });
                } else{
                    // Clicked outside the box
                    const modals = document.querySelectorAll('.showModal');
                    modals.forEach(modal => {
                        modal.classList.replace('showModal','hideModal');
                    });
        
                }  
                e.stopPropagation();     
            }catch(err){
                console.log("No Modal yet")    
            }
        });
    }

    useEffect(() => {
        handleClicksToCloseModal();
        auth()
    }, []);

    if(!loaded){
        auth();
    }

    return (
        <>
            <>{!loaded ? <Loading/> : null}</>
            <NotificationTab/>
            <div className='main-page-outer'>
                <Header/>
                <div className='main-page'>
                    {<LeftBar tab={tab}/>}
                    {/* Right Side of App */}
                    <div className='right-bar'>
                        <div className='flex'>
                            {/*<Breadcrumbs/>*/}
                        </div>
                        {loaded ? <Outlet/> : null}
                    </div>
                </div>              
            </div>
        </>
        
    )
}

function mapStateToProps(state) {
    return {
        user : state.user
    };
  }

  export default connect(mapStateToProps)(AppPage);
