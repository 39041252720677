import React, { useState } from 'react';
import './index.scss'; // Ensure the SCSS file is imported
import ReactStars from 'react-stars';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const CardVertical = ({ image, reviewer, rating, text, carModel }) => {
    const { t, i18n } = useTranslation();

    const [isExpanded, setIsExpanded] = useState(false); // To track text expansion

    const toggleReadMore = () => {
        setIsExpanded(!isExpanded);
    };
    const previewText = text.length > 300 ? text.substring(0, 300) + '...' : text;

    return (
        <div className="card">
        {carModel 
        ?
        <img src={image} alt={carModel} className="card-image" />
        :
        <></>
        }
        <div className="card-body">
            <div className="card-title"> <h3>{reviewer}</h3></div>
            <div className="card-rating">
                <ReactStars
                    count={5}
                    value={rating}
                    size={24}
                    color2={'#ffd700'} // Color of the stars
                    edit={false} // Make the stars read-only
                />
            </div>
            <div className="card-text">
                <h4>
                    {/* Show either the full text or the preview */}
                    {isExpanded ? text : previewText}
                    {/* Conditionally render "Read more" or "Show less" based on the state */}
                    {text.length > 300 && (
                        <div className="button" onClick={toggleReadMore}>
                            <h5 className='bold-text'>{isExpanded ? t('show_less') : t('read_more')}</h5>
                        </div>
                    )}
                </h4>
            </div>
        </div>
        {/* <div className="card-footer">
            <div className="car-model"><h4>{carModel}</h4></div>
            <Link href="#" className="show-similar"><h5 className='link'>Show similar cars</h5></Link>
        </div> */}
        </div>
    );
};

export default CardVertical;
