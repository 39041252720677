/* Create Image Object with src, width, and height */
import React from 'react';
import './index.scss';

const Image = (props) => {
    const { src, width, height, className } = props;
    return (
        <img className={'image '+ className} src={src} width={width} height={height} />
    )
}

export default Image;