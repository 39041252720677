/* Create Horizontal Card Car Component */
import React, { useEffect, useState } from 'react';
import './index.scss';
import { useNavigate } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { connect } from 'react-redux';
import ImageDisplayer from '../../ImageDisplayer/index.js';
import { isMobile } from '../../../../utils/utils.js';
import { useTranslation } from 'react-i18next';
import { promotedIcon } from '../../../../assets/images/index.js';
import { CalendarToday, FiberManualRecord, LocalGasStation, LocationOn, PowerInput, Speed } from '@mui/icons-material';
import { formatNumber } from '../../../../utils/number.tsx';

const CardCompact = ({car}) => {

    const navigate = useNavigate();

    const toCarPage = () => {
        if(car.available)
            //window.open(`${window.location.origin}/car?id=${car.id}`);
            navigate(`/car?id=${car.id}`);
    }
    
    const characteristicTotalNumber = car.other.length + car.security.length + car.multimedia.length +
                                    car.interior.length;
    const { t, i18n } = useTranslation();

    const mobile = isMobile(window);

    return (
        <div className={`hover:cursor-pointer compact-card `}>
            <div className='row-start-1 row-end-1'>
                <ImageDisplayer className="absolute-carousel" 
                    imageOnClick={toCarPage}
                    imageUrls={car.photos} imageClasses="carousel-image-small" 
                    showThumbs={false} showStatus={false} showArrows={true}/>
            </div>
            <div className='absolute promoted center align-middle'>
                <img className='mr-1' src={promotedIcon}/>
                <h6>{t('promoted_3')}</h6>
            </div>
            <div onClick={() => toCarPage()} className='row-start-2 row-end-2 info-container flex justify-between overflow-hidden'>
                <h4 className='bold-text text-left'>{car.make} {car.model} {car.version}</h4>
            </div>

            <div onClick={() => toCarPage()} className='row-start-3 row-end-3 info-container flex justify-between'>
                <div className=''>
                    <div className='inline'>
                        <Speed className='icon inline grey'/>
                        <h5 className='grey inline'>{formatNumber(car.mileage)} km</h5>
                    </div>
                    <div className='inline'>
                        <CalendarToday className='icon inline grey' />
                        <h5 className='grey inline'>{car.year}</h5>
                    </div>
                    <div className='inline'>
                        <PowerInput className='icon inline grey' />
                        <h5 className='grey inline'>{car.power} hp</h5>
                    </div>
                    <div className='inline'>
                        <LocalGasStation className='icon inline grey' />
                        <h5 className='grey inline'>{car.CarFuel[i18n.language]}</h5>
                    </div>
                </div>
                {
                    mobile ?
                    <></>
                    :
                    <div className='flex flex-wrap items-center'>
                        <div className='tags characteristics'>
                            {car.interior.map((characteristic, index) => {
                                if (index <= 4)
                                    return <h6 key={index} className='inline tag'>{characteristic[i18n.language + '_translation']}</h6>
                                else return <></>
                            })}
                        </div>
                        <h6>{"+" + (characteristicTotalNumber - 4) + " " + t('other').toLowerCase()}</h6>
                    </div>
                }
                <div className='mb-5 grid w-full'>
                    <div className='left'>
                        <div className='items-start !p-0'>
                            <LocationOn className='icon grey'/>
                            <h6 className='grey inline'>{car.Country[i18n.language]}</h6>
                        </div>
                        <div className={`tag ${car.available ? 'green' : 'red'} available`}>
                            <FiberManualRecord className='inline'/>
                            <h6 className='inline'>{car.available ? t('available') : t('unavailable')}</h6>
                        </div>
                    </div>
                    <div className='right'>
                        <h4 className='align-top bold-text text-right'>{formatNumber(car.price)} €</h4>
                        {/* <h5 className='grey'>{formatNumber(car.priceWithoutVat)} € {t('without_vat')}</h5> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    const user = state.user;
    return {
        user
    };
}

export default connect(mapStateToProps)(CardCompact);
