/* Create Brand Picker Modal */
/* Should be able to select a brand from a list of brands */
/* Should be able to search for a brand */
/* Should be able to create a new brand */

import { connect } from "react-redux";
import "./index.scss";
import 'react-calendar/dist/Calendar.css';
import { withTranslation } from "react-i18next";
import { isValidEmail, renderInput, renderItemList, truncateStringByLimitWords } from "../../lib/Helpers";
import { Component } from "react";
import { filtersSearch, getFilterValues } from "../../../filters/search";
import { Button } from "@mui/material";
import APIConnection from "../../api"
import ContainedLoading from "../ContainedLoading";
import { withNavigate } from "../../../utils/navigator";

const mandatoryKeys = [
    'url',
    //'email',
    //'phoneNumber'
]
const formDefault = {
    'url': '',
    'email': '',
    'phoneNumber': ''
}
class CarFindForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            form: formDefault,
            disableButton:true,
        }
    }

    onChange = (key, value, parentField, options={variable : false, array : false}) => {
        this.setState(prevState => ({
            form: {
                ...this.state.form,
                [key]: value
            }
        }),() => {
            let disable = false;
            for(let i = 0; i < mandatoryKeys.length; i++){
                if(this.state.form[mandatoryKeys[i]] === null || this.state.form[mandatoryKeys[i]] === undefined 
                    || this.state.form[mandatoryKeys[i]] == ''){
                    disable = true;
                    break;
                }
            }
            
            // if(!disable)
            //     disable = !isValidEmail(this.state.form['email']);
            
            if(this.state.disableButton != disable){
                this.setState({disableButton: disable});
            }
        });
    }

    sendRequest = async () => {
        //TODO
        const form = this.state.form;
        const response = await APIConnection.createRequestFind({form});
        console.log(response);

        if(response.status !== 200){
            //alert(t('request_4'));
            this.setState({disableButton: false});
        }else{
            this.props.navigate('/car?id='+response.message.id);
            return;
        }
    }

    render = () => {
        const { user, t } = this.props;

        return (
            <div className={`car-find-form-container`}>
                <div className="flex flex-col !items-start">
                    <div className="flex flex-row !items-start w-full">
                        <span className="inline">
                            <h2>{t('find_your_car_6')}</h2>
                            {<h5 className='!text-red-600 !pt-1 !pb-0 !pl-2 !pr-0'>*</h5> }
                        </span>
                        {renderInput({title : '', keyItem : 'url', placeholder : t('find_your_car_7'),
                            form:this.state.form,
                            onChange: this.onChange
                        })}
                    </div>
                    <div className="grid-50">
                        {renderInput({type:'email',title : t('email'), keyItem : 'email', placeholder : t('email'),
                            form:this.state.form,onChange: this.onChange,isRequired: true,
                            isValid:isValidEmail(this.state.form['email']), error:t('signup_3')
                        })}
                        {renderInput({type:'tel',title : t('phone_number'), keyItem : 'phoneNumber', placeholder : t('phone_number'),
                            form:this.state.form,onChange: this.onChange,isRequired: true
                        })}
                    </div>
                    <Button className={`button ${this.state.disableButton ? 'btn-disabled' : ''} fit ml-5`} onClick={() => this.sendRequest()}>
                        <h6>{t('request_2')}</h6>
                    </Button>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user : state.user,
    };
}

export default connect(mapStateToProps)(withTranslation()(withNavigate(CarFindForm)));