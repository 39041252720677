import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import Image from '../Image';
import './index.scss';
import { hamburgerMenu, logo } from '../../../assets/images';
import UrlNavigatorSingleton from '../../lib/UrlNavigator';
import { ROUTES } from '../../route';
import { AccountBoxOutlined, Close, FavoriteBorderOutlined, KeyboardArrowDown, Person2Outlined } from '@mui/icons-material';
import ConnectionAPI from '../../api';
import Button from '../Button';
import UserAPI from '../../lib/User';
import { useTranslation } from 'react-i18next';
import { isMobile } from '../../../utils/utils';
import { deleteStoredFilters } from '../../../forms/search';
import { Helmet } from 'react-helmet';
import { IS_PRODUCTION } from '../../../config';
import { scrollToTopPage } from '../../pages/Utils/utils';
import { pixelBuyPageFromHeader } from '../../../utils/pixel';

const Header = React.memo((props) => {
    const location = useLocation();
    const [favorites, setFavorites] = React.useState(0);
    const { t, i18n } = useTranslation();
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);

    React.useEffect(() => {
        /* Verify if Logged in */
        login();
    }, []);

    const login = async () => {
        let isAuth = await UserAPI.auth();
        if(isAuth)
            getFavorites();
    }

    const getFavorites = async () => {
        const response = await ConnectionAPI.getFavorites();
        if (response.status === 200) {
            setFavorites(response.message.cars.length);
        }
    }

    const openModalProfile = () => {
        console.log('openModalProfile');
    };

    const emptyDropDown = (key) => {

    };

    const onChange = (key, value, parentField) => {

    };

    const findRoutePathByKey = (key) => {
        const recursiveFind = (routeArray, key) => {
            for (const route of routeArray) {
                if (route.key === key) {
                    return route.path;
                }
                if (route.children) {
                    const foundPath = recursiveFind(route.children, key);
                    if (foundPath) return foundPath;
                }
            }
        };
        return recursiveFind(ROUTES, key);
    };

    const renderNavLink = ({ path, breadcrumb, key }, mobile=false) => {
        const isActive = key === 'home' ? location.pathname === '/' : UrlNavigatorSingleton.isInPage(key);

        return (
            <Link
                key={key}
                to={path}
                className={`${mobile ? 'block mb-5' : 'inline'} ${isActive ? 'isActiveHeader' : ''}`}
                onClick={() => {
                    if(path === '/buy')
                        pixelBuyPageFromHeader();
                    deleteStoredFilters();
                    scrollToTopPage();
                }}
            >
                {
                    mobile ? 
                    <h4>{t(breadcrumb)}</h4>
                    :
                    <h5>{t(breadcrumb)}</h5>
                }
                
            </Link>
        );
    };

    const showMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    }

    const isUserLoggedIn = props.user && props.user.id;
    const username = /* Two first letters in uppercase */ isUserLoggedIn ? String(props.user.name).toUpperCase().slice(0, 2) : '';

    const mobile = isMobile(window);

    useEffect(() => {
        if(!mobile)
            setIsMenuOpen(true);
    },[mobile]);

    if(mobile){
        return (
            <>
                <div className={`${isMenuOpen ? 'nav-links z-50 p-5' : 'z-50 nav-links-closing'}`}>
                    <Close  onClick={() => showMenu()}/>
                    <Image className="mt-5 mb-5" width="170px" src={logo} />
                    <div className=''>
                        {ROUTES.filter(route => route.header).map(route => renderNavLink(route,true))}
                        {ROUTES.filter(route => route.children).flatMap(route =>
                            route.children.filter(child => child.header).map(child =>
                                renderNavLink({ ...child, path: `${route.path}/${child.path}` },true)
                            )
                        )}
                    </div>
                </div>
                    
                <div className='header-wrapper'>
                    <div className='header z-50'>
                        <img src={hamburgerMenu} height={16} onClick={showMenu} />

                        <Link to='/' className='!ml-2'>
                            <Image src={logo} />
                        </Link>
        

                        <div className='account-header-container'>
                            {isUserLoggedIn ?
                                <div className='inline-no-margin items-center justify-center !mr-2'>
                                    <Link className='inline-no-margin !mr-2' to={'/app/favorites'}>
                                        <FavoriteBorderOutlined className='favorite-icon' />
                                        <h5 className='favorite-count'>{favorites}</h5>
                                    </Link>
                                    <Link to='/app/account' className='inline-no-margin'>
                                        <h5 className='username-account-icon'>{username}</h5>
                                    </Link>
                                </div>
                                :
                                <Button className={'center-mobile-icon button fit icon-button'} style={{ marginLeft: 0 }} onClick={() => UrlNavigatorSingleton.__toPage('/signup')}>
                                    <Person2Outlined className='icon' />
                                </Button>
                            }
                        </div> 
                    </div>
                </div>
            </>);
    }

    return (
        <>
            <div>
                <div className='header fixed z-50'>
                    <div className='inline'>
                        <Link to='/' className='logo'>
                            <Image src={logo} height='100%' width='100px' />
                        </Link>
                    </div>
                    <>
                        <div className='nav-links'>
                            {ROUTES.filter(route => route.header).map(route => renderNavLink(route))}
                            {ROUTES.filter(route => route.children).flatMap(route =>
                                route.children.filter(child => child.header).map(child =>
                                    renderNavLink({ ...child, path: `${route.path}/${child.path}` })
                                )
                            )}
                        </div>
                        <div className='account-header-container'>
                                {isUserLoggedIn ?
                                    <div className='flex items-center justify-center'>
                                        <Link className='inline' to={'/app/favorites'}>
                                            <FavoriteBorderOutlined className='favorite-icon' />
                                            <h5 className='favorite-count'>{favorites}</h5>
                                        </Link>
                                        <Link to='/app/account' className='inline'>
                                            <h5 className='username-account-icon'>{username}</h5>
                                        </Link>
                                    </div>

                                    :
                                    <Button className={'button fit icon-button inline items-center'} style={{ marginLeft: 8 }} onClick={() => UrlNavigatorSingleton.__toPage('/signup')}>
                                        <Person2Outlined className='icon' />
                                        <h4 className=''>{t('login')}</h4>
                                    </Button>
                                }
                                {/*<button className='inline' onClick={openModalProfile}>
                                    <h4 className='text'>Profile</h4>
                                    <KeyboardArrowDown className='' style={{marginTop : 2}}/>
                                </button>*/}
                        </div>             
                    </> 
                </div>
            </div>
            <div className='header-margin' />
        </>
    );
});

function mapStateToProps(state) {
    return {
        user: state.user,
        router: state.router,
        app: state.app
    };
};

export default connect(mapStateToProps)(Header);
