export const API_URL = process.env.REACT_APP_API_URL;

export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

export const PAGE_SIZE = process.env.PAGE_SIZE || 20;

export const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID || '';

export const INTERCOM_SECRET = process.env.REACT_APP_INTERCOM_SECRET || '';

export const GOOGLE_ANALYTICS_MEASUREMENT_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID|| '';

export const IS_PRODUCTION = (process.env.REACT_APP_ENV === 'production') ? true : false;

export const FACEBOOK_PIXEL = process.env.REACT_APP_FACEBOOK_PIXEL || '';
