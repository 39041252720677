import { Component } from 'react';
import { connect } from 'react-redux';
import './index.scss'
import ContainerDropDown from '../Container';
import CheckBox from '../../CheckBox';

class MultiSelectDropDown extends Component{
    constructor(props){
        super(props)
        this.state = {
            age : null
        }
    }

	handleChange = (name, event) => {
		let value = event.target.value;
		this.setState({item : value});
		this.props.onChangeFilters(this.props.keyItem, name, value);
	};

	emptyDropDown = () => {
		this.props.emptyDropDown(this.props.keyItem);
	}
    
    render = () => {
		const { title, list, filters, keyItem, id, placeholder} = this.props;
        return (
			<ContainerDropDown        
				id={id}
				placeholder={placeholder}
				emptyDropDown={this.emptyDropDown} keyItem={keyItem} filters={filters} title={title}>
				<div>
					{list.map( (item, index) => {
						return (
							<CheckBox 
								key={index}
								filters={filters} 
								name={item}
								amount={index}
								keyItem={keyItem} 
								valueItem={item}
								handleChange={(event) =>this.handleChange(item.subType, event)}/>
						)
                    })}
				</div>
			</ContainerDropDown>
        )
    }
    
}

function mapStateToProps(state) {
    const user = state.user;
    return {
        user
    };
  }

  export default connect(mapStateToProps)(MultiSelectDropDown);
