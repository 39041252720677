
export const formSearch = {
    filters : {
        model : '',
        make : '',
        vehicleType : '',
        registrationFrom : '',
        registrationTo : '',
        priceFrom : '',
        color : '',
        priceTo : '',
        mileageFrom : '',
        mileageTo : '',
        powerFrom : '',
        powerTo : '',
        powerUnit: '',
        fuel : '',
        transmission : '',
        version : [],
        engine : [],
    }
}

export const CAR_FILTER_SESSION_STORAGE_KEY = 'carFilters';
export const CAR_SORT_SESSION_STORAGE_KEY = 'carSort';

export const deleteStoredFilters = () => {
    sessionStorage.removeItem(CAR_FILTER_SESSION_STORAGE_KEY);
    sessionStorage.removeItem(CAR_SORT_SESSION_STORAGE_KEY);
}

export const getStoredCarFilters = () => {
    if(sessionStorage.getItem(CAR_FILTER_SESSION_STORAGE_KEY) !== undefined)
        return JSON.parse(sessionStorage.getItem(CAR_FILTER_SESSION_STORAGE_KEY));
    return undefined;
}

export const setStoredCarFilters = (filters) => {
    sessionStorage.setItem(CAR_FILTER_SESSION_STORAGE_KEY, JSON.stringify({filters: filters}));
}

export const getCarSortFilter = () => {
    if(sessionStorage.getItem(CAR_SORT_SESSION_STORAGE_KEY) !== undefined)
        return JSON.parse(sessionStorage.getItem(CAR_SORT_SESSION_STORAGE_KEY));
    return undefined;
}

export const setCarSortFilter = (sort) => {
    sessionStorage.setItem(CAR_SORT_SESSION_STORAGE_KEY, JSON.stringify({sort: sort}));
}