import React from 'react';
import './index.scss';
import { checkMark,crossMark } from '../../../assets/images';
import { useTranslation } from 'react-i18next';
import { isMobile } from '../../../utils/utils';

const TableHero = () => {
    const { t, i18n } = useTranslation();
    const content = []
    let aux = new Map();
    aux['isAdvantage'] = true;
    aux['category'] = 'Advantages';
    aux[t('hybrid_12')] = [t('hybrid_13'), t('hybrid_14'), t('hybrid_15')];
    aux[t('hybrid_19')] = [t('hybrid_20'), t('hybrid_21'), t('hybrid_22')];
    aux[t('hybrid_26')] = [t('hybrid_27'), t('hybrid_28'), t('hybrid_29')];
    aux[t('hybrid_33')] = [t('hybrid_34'), t('hybrid_35'), t('hybrid_36')];
    aux[t('hybrid_40')] = [t('hybrid_41'), t('hybrid_42'), t('hybrid_43')];

    content.push(aux);

    aux = new Map();
    aux['isAdvantage'] = false;
    aux['category'] = 'Disadvantages';
    aux[t('hybrid_12')] = [t('hybrid_16'), t('hybrid_17'), t('hybrid_18')];
    aux[t('hybrid_19')] = [t('hybrid_23'), t('hybrid_24'), t('hybrid_25')];
    aux[t('hybrid_26')] = [t('hybrid_30'), t('hybrid_31'), t('hybrid_32')];
    aux[t('hybrid_33')] = [t('hybrid_37'), t('hybrid_38'), t('hybrid_39')];
    aux[t('hybrid_40')] = [t('hybrid_44'), t('hybrid_45'), t('hybrid_46')];
    content.push(aux);

    const table = {
        "Types": [
            t('hybrid_12'),
            t('hybrid_19'),
            t('hybrid_26'),
            t('hybrid_33'),
            t('hybrid_40'),
        ],
        "Content": content,
    }

    const mobile = isMobile(window);
    return (
        <div className='section box-background'>
            <div className='container'>
                <h2 className='center'>{t('hybrid_11')}</h2>
                <div className='small-container'>
                    <table className='comparison-table'>
                        <thead>
                            <tr>
                                {table.Types.map((type, index) => (
                                    <th key={index}>
                                        {
                                            mobile ?
                                            <h6>{type}</h6>
                                            :
                                            <h4>{type}</h4>
                                        }
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {table.Content.map((item, index) => (
                                <tr key={index}>
                                    {table.Types.map((type, typeIndex) => (
                                        <td className={item.isAdvantage ? 'advantage-row' : 'disadvantage-row'} key={typeIndex}>
                                            <div className='stack'>
                                                <img src={item.isAdvantage ? checkMark : crossMark}/>
                                                <ul>
                                                    {   
                                                    item[type].map(function (value, index, array){
                                                        if(mobile)
                                                            return <li><h6>{value}</h6><br></br></li>;
                                                        return <li><h4>{value}</h4><br></br></li>;
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default TableHero;
