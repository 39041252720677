import './index.scss';
import { Component } from 'react';
import { connect } from 'react-redux';
import Loading from '../../components/Loading';
import { MetaTags } from 'react-meta-tags';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import ReviewsHero from '../../sections/ReviewsHero';
import GoogleReviews from '../../sections/GoogleReviews';
import ConnectionAPI from '../../api';


class ReviewsPage extends Component{
    constructor(props){super(props);
    this.state = {loading : false, reviews: null}}
    
    componentDidMount(){
        this.getGoogleReviews();
    }    

    getGoogleReviews = async () => {
        const response = await ConnectionAPI.getGoogleReviews();
        if(response.status === 200){
            let reviews = response.message;
            this.setState({ loading:false, reviews: reviews });
        }else{
            this.setState({ loading: false });
        }
    }

    render = () => {
        const { loading, reviews } = this.state;

        return (
            <>  
                <>{ (loading) ? <Loading/> : null}</>
                <MetaTags>
                    <title> Versicar </title>
                    <meta name="description" content="Buy your best car" />
                    <meta property="og:title" content="Versicar" />
                </MetaTags>
                {/* Header */}
                <Header/> 
                {/* Main Container */}
                {reviews != null ?
                <ReviewsHero rating={reviews.rating}/>
                :
                <></>
                }
                {/* Google Reviews */}
                {reviews != null ?
                <GoogleReviews reviews={reviews.values}/>
                :
                <></>
                }
                {/* Footer */}
                <Footer/>
            </>
            )
    }
    
}

function mapStateToProps(state) {
    const user = state.user;
    return {
        user
    };
  }

  export default connect(mapStateToProps)(ReviewsPage);
