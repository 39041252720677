import { PAGE_SIZE } from "../../config";
import Select from '../components/DropDown/Select';
import DualOptionSelector from '../components/DualOptionSelector';
import MultiOptionSelector from '../components/MultiOptionSelector';
import InputText from '../components/InputText';
import { isNumeral } from "numeral";
import { isNumber } from "../../utils/utils";

export function removeEmptyArraysAndKeys(r) {
  var obj = {...r};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (Array.isArray(obj[key])) {
          obj[key] = obj[key].filter(value => value !== undefined && value !== null);
          
          if (obj[key].length === 0) {
            delete obj[key];
          }
        } else if (obj[key] === null || obj[key] === undefined) {
          delete obj[key];
        }
      }
    }

    /* Remove fileds with empty strings as values */
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (typeof obj[key] === 'string' && obj[key].length === 0) {
          delete obj[key];
        }
      }
    }

    return obj;
  }
  

export function getSizeAndOffsetFromPageNumber(page){
  return {
    size : PAGE_SIZE,
    offset : (page*PAGE_SIZE)-PAGE_SIZE
  }
}

export function roundUpIfNotInteger(value){
    if (typeof value !== 'number') {
        throw new Error("Input must be a numeric value");
    }

    if (Number.isInteger(value)) {
        return value;
    } else {
        return Math.ceil(value);
    }
}

function getIntersectionObjectCaseInsensitive(obj, targetArray) {
    let intersectionObject = {};

    // Iterate through all keys in the object
    for (let key in obj) {
        if (obj.hasOwnProperty(key) && Array.isArray(obj[key])) {
            // Convert all strings to lowercase for case-insensitive comparison
            let lowercaseTargetArray = targetArray.map(value => value.toLowerCase());
            
            // Use filter to keep values that are present in both arrays (case-insensitive)
            let keyIntersection = obj[key].filter(value => lowercaseTargetArray.includes(value.toLowerCase()));

            // Add the keyIntersection to the result object
            intersectionObject[key] = keyIntersection;
        }
    }

    return intersectionObject;
}

export function formatDuration(durationInSeconds) {
    const minutes = Math.floor(durationInSeconds / 60);
    const seconds = Math.floor(durationInSeconds % 60);
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
    return `${minutes}:${formattedSeconds}`;
}

export const returnFileNameExtensionFromURL = (fileURL) => {
return (/[.]/.exec(fileURL)) ? "." + /[^.]+$/.exec(fileURL) : undefined;
}
export const  formatNumberWithCommas = (number) => {
    // Convert the number to a string
    let numberString = number ? number.toString() : '';

    // Use a regular expression to add commas every 3 digits
    numberString = numberString.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return numberString;
}

export function isValidEmail(email) {
  // Regular expression for a basic email validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

export function isValidPassword(password) {
  // Check if the password is not null, undefined, or an empty string
  if (!password || typeof password !== 'string') {
    return false;
  }

  // Check if the password has more than 7 characters
  return password.length > 7;
}

export function isValidName(name) {
  // Check if the name is not null, undefined, or an empty string
  if (!name || typeof name !== 'string') {
    return false;
  }

  // Check if the name length is within the valid range (1 to 30 characters)
  return name.length > 3 && name.length <= 30;
}

export function addOneMonth(date) {
  // Copy the original date to avoid mutating it
  const newDate = new Date(date);

  // Increment the month by 1
  newDate.setMonth(newDate.getMonth() + 1);

  return newDate;
}

export function generateRandomString(length=10) {
  // Define all the characters that can be used in the random string
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  // Initialize the result string
  let result = '';

  // Generate a random character and add it to the result string
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  
  return result;
}


export const getCSSVariableValue = (variableName) => {
  return getComputedStyle(document.documentElement).getPropertyValue(variableName).trim();
};

export function truncateString(str, length){
   if (str.length <= length) {
        return str;
    } else {
        return str.slice(0, length) + '...';
    }
}

export function truncateStringByLimitWords(str, limit){
  const words = str.split(' ');
  if (words.length <= limit) {
    return str;
  } else {
    return words.slice(0, limit).join(' ');
  }
}

export function priceFormat(price){
    price = price/3 + 0.97;
    /* Price has to end in seven */
    price = price.toFixed(2);
    price = price.substring(0, price.length - 1) + '7';
    return `$${price}`;
}


export const getObjectFromQueryString = ({queryString}={}) => {
  /* If the query string is empty get from params */
  if(!queryString){
    queryString = window.location.search;
  }
  let obj = {};
  /* Remove the ? */
  queryString = queryString.substring(1);
  /* Split the query string */
  let queries = queryString.split('&');
  /* Iterate over the queries */
  queries.forEach(query => {
    let [key, value] = query.split('=');
    
    obj[key] = decodeURIComponent(value);
    if(obj[key] && isNumber(obj[key] + "")){
      obj[key] = parseInt(obj[key])
    }
  });
  /* Remove empty keys */
  obj = removeEmptyArraysAndKeys(obj);
  return obj;
}

export const getQueryStringFromObject = ({filters}) => {
  let queryString = '';
  for(let key in filters){
    queryString += `${key}=${filters[key]}&`
  }
  return queryString;
}

//if the defaultOnEmpty is true when the input element is cleared out the default value will be its value
export const renderInput = ({onChange,form,isRequired, title, placeholder, type, parentField, keyItem, defaultValue, defaultOnEmpty,
  error,isValid=true,textArea, rows=10, cols=10
}) => {
  return (
          <InputText 
              onChange={onChange} 
              form={form}
              isValid={isValid}
              error={error}
              parentField={parentField}
              title={title} 
              placeholder={placeholder} 
              type={type}
              keyItem={keyItem}
              defaultValue={defaultValue}
              defaultOnEmpty={defaultOnEmpty}
              isRequired={isRequired}
              textArea={textArea}
              rows={rows}
              cols={cols}
          />
  )
}

export const renderItemList = ({isRequired,emptyDropDown,form,title, placeholder, keyItem, parentField, allFilters,onChange,search,
  allowNewOption=false,id=1
}) => {
  return (
     <Select    
              id={id}
              form={form}
              isRequired={isRequired}
              parentField={parentField}
              keyItem={keyItem}
              emptyDropDown={emptyDropDown}
              onChange={onChange}
              placeholder={placeholder}
              list={allFilters[keyItem]} 
              title={title}
              isSearch={search}
              allowNewOption={allowNewOption}
    />
  )
}

export const renderDualOptionItem = ({isRequired,title, options, keyItem, parentField,onChange,form,canDeselect=false}) => {
  return (
     <DualOptionSelector isRequired={isRequired}
     title={title} keyItem={keyItem} options={options} onChange={onChange} parentField={parentField} form={form} canDeselect={canDeselect}/>
  )
}

export const renderMultiOptionItem = ({isRequired,form,title, options, keyItem, parentField,onChange}) => {
  return (
      <MultiOptionSelector isRequired={isRequired} form={form} title={title} keyItem={keyItem} options={options} onChange={onChange} parentField={parentField}/>
  )
}

export const renderMultiSelection = ({isRequired,form,onChange, title, options, keyItem, parentField}) => {
  return (
      <MultiOptionSelector isRequired={isRequired} variable={true} form={form} title={title} keyItem={keyItem} options={options} onChange={onChange} parentField={parentField}/>
  )
}