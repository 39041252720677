/* Create Hero Section with Image and Text */
import React, {useState,useEffect} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import './index.scss';
import CheckBox from '../../components/CheckBox';
import { hero } from '../../../assets/images';
import { EnhancedEncryptionOutlined, FavoriteBorderOutlined, SupportOutlined } from '@mui/icons-material';
import Select from '../../components/DropDown/Select';
import { mainFormSearch } from '../../../forms/mainsearch';
import { filtersSearch, getFilterValues } from '../../../filters/search';
import { getQueryStringFromObject,renderItemList } from '../../lib/Helpers';
import ConnectionAPI from '../../api';
import { useTranslation } from 'react-i18next';
import InformationCard from '../../components/InformationCard';
import Loading from '../../components/Loading';
import { isMobile } from '../../../utils/utils';
import { deleteStoredFilters } from '../../../forms/search';
import PromotedCarsPage from '../../components/PromotedCarsPage';
import ContainedLoading from '../../components/ContainedLoading';
import FAQContainer from '../FAQContainer';
import NewUserPromotionModal from '../../components/Modal/NewUserPromotionModal';
import CantFindCard from '../../components/CantFindCard';
import { pixelBuyPageFromFilter } from '../../../utils/pixel';


const formDefault = mainFormSearch();

const MainHero = () => {
    const [form, setForm] = useState(formDefault);
    let [offers, setOffers] = useState('');
    const [offersLoading, setOffersLoading] = useState(true);
    const [allFilters, setAllFilters] = useState(null);
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const emptyDropDown = (subType) => {
        if(subType == 'make'){
            form.filters.model = '';
        }
        if(subType == 'registrationFrom'){
            updateDependencyValues(null,'registrationFrom','registrationTo');
        }else if(subType == 'priceFrom'){
            updateDependencyValues(null,'priceFrom','priceTo');
        }
        setForm({...form, filters : {...form.filters, [subType] : ''}});
    };
    
    const search = () => {
        /* Go to Buy Page and add search params */
        /* Set Query Paras from Form */
        const query = getQueryStringFromObject({filters : form.filters});
        pixelBuyPageFromFilter();
        navigate(`/buy?${query}`);
    };

    const onChange = async (key, value, parentField, options={variable : false, array : false}) => {
        if(options.array){
            if(options.variable){
                const array = this.state.form[parentField][key];
                if(array.includes(value)){
                    const index = array.indexOf(value);
                    array.splice(index, 1);
                }else{

                    array.push(value);
                }
                setForm({...form, [parentField] : {...[parentField], [key] : array}});
            }else{
                setForm({...form, [parentField] : {...form[parentField], [key] : [value]}});
            }
        }else{
            setForm({...form, [parentField] : {...form[parentField], [key] : value}});
        }
    }

    const updateDependencyValues = async (value,parent,dependent) => {
        const newValues = []
        const allowedValues = allFilters[parent];
        for(let i = 0; i < allowedValues.length; i++){
            if(value == null || allowedValues[i] >= value){
                newValues.push(allowedValues[i]);
            }
        }

        const newAllFilters  = allFilters;
        newAllFilters[dependent] = newValues;
        setAllFilters(newAllFilters);
    }

    const onChangeDependent = async (key, value, parentField, options={variable : false, array : false}) => {
        let dependent = '';
        switch(key){
            case 'registrationFrom':
                dependent='registrationTo';
                break;
            case 'priceFrom':
                dependent='priceTo';
                break;
        }

        if(form[parentField][dependent] < value){
            form.filters[dependent] = '';
            setForm(form);
        }
        
        updateDependencyValues(value,key,dependent);

        onChange(key,value,parentField,options);
    }

    const renderItemListFilter = ({ title, placeholder, keyItem, parentField, search, allowNewOption, onChangeFunction }) => {
        return (
                renderItemList({
                    form:form,
                    parentField:parentField,
                    keyItem:keyItem,
                    emptyDropDown:emptyDropDown,
                    onChange:onChangeFunction,
                    placeholder:placeholder,
                    allFilters:allFilters,
                    title:title,
                    search:search,
                    allowNewOption:allowNewOption
                    }
                )
        );
    };


    async function getOffers() {
        let res = await ConnectionAPI.getNumberOfOffers(await getFilterValues(t,i18n.language,form.filters));
        setOffers(res.message.length);
    };

    useEffect(() => {
        async function setFilters() {
            const filters = await filtersSearch(t,i18n.language, form.filters.make);
            setAllFilters(filters);
        };

        setFilters();

        setOffersLoading(true);
      }, [form]);

      useEffect(() => {
        if(offersLoading){
            getOffers();
            setOffersLoading(false);
        }
      }, [offersLoading]);

    const mobile = isMobile(window);
    return (
        <>
            <div>
                <div className='main-hero'>
                    <div className='background'></div>
                    <img src={hero} alt='hero' className='hero-image' />
                    <div className='content'>
                        {
                            allFilters == null ? <ContainedLoading/>
                            :
                            <div className='container'>
                                <h2 className='main-hero-title'>{t('home_1')}</h2>
                                <h2 className='main-hero-subtitle'>{t('home_2')}</h2>
                                <div className='small-container w-full'>
                                    <div className={mobile ? '' : 'grid-33'}>
                                        <div className=''>
                                            {renderItemListFilter({ title: t('make'), placeholder: t('make'), keyItem: 'make', parentField: 'filters'
                                                , search:true, onChangeFunction: onChange})}
                                        </div>
                                        <div className=''>
                                            {renderItemListFilter({ title: t('model'), placeholder: t('model'), keyItem: 'model', parentField: 'filters'
                                                , search:true, onChangeFunction: onChange
                                            })}
                                        </div>
                                        <div className=''>
                                            {renderItemListFilter({ title: t('fuel'), placeholder: t('fuel'), keyItem: 'fuel', parentField: 'filters', onChangeFunction: onChange})}
                                        </div>
                                    </div>
                                    <div className={mobile ? '' : 'grid-33'}>
                                        <div className='pt-5'>
                                            <h5 className='bold-text'>{t('registration')}</h5>
                                            <div className='grid-50'>
                                                {renderItemListFilter({keyItem : 'registrationFrom',  placeholder : t('from'), parentField : 'filters', search: true, onChangeFunction: onChangeDependent})}
                                                {renderItemListFilter({keyItem : 'registrationTo',  placeholder : t('to'), parentField : 'filters', search: true, onChangeFunction: onChange})}
                                            </div>
                                        </div>
                                        <div className='pt-5'>
                                            <h5 className='bold-text'>{t('price')} (EUR)</h5>
                                            <div className='grid-50'>
                                                {renderItemListFilter({keyItem : 'priceFrom', placeholder : t('from'), parentField : 'filters', search: true,allowNewOption: true, onChangeFunction: onChangeDependent})}
                                                {renderItemListFilter({keyItem : 'priceTo', placeholder : t('to'), parentField : 'filters', search: true,allowNewOption: true, onChangeFunction: onChange})}
                                            </div> 
                                        </div>
                                    </div>
                                    <div className='grid-33 small-container items-center '>
                                        <div className='left'>
                                            <Link to='/buy' onClick={() => deleteStoredFilters()}>
                                                <h5 className='link'>{t('home_3')}</h5>
                                            </Link>
                                        </div>
                                        <Button className='button fit ml-auto mr-0 col-start-3' onClick={search}>
                                            {offersLoading ? <ContainedLoading/> : 
                                                <h4>{t('home_10') +  ' ' + offers + ` ${t('offers')}`}</h4>
                                            }
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <PromotedCarsPage/>
                <center className='mb-10 container'>
                    <CantFindCard />
                </center>
                <div className='section z-0'>
                    <div className={`container ${mobile ? '' : 'grid-33'}`}>
                        <InformationCard greyBackground={true} center={true} title={<FavoriteBorderOutlined fontSize='large' />} 
                            subTitle={t('home_4')} content={t('home_5')}/>
                        <InformationCard  greyBackground={true} center={true} title={<EnhancedEncryptionOutlined fontSize='large' />} 
                                subTitle={t('home_6')} content={t('home_7')}/>
                        <InformationCard  greyBackground={true} center={true} title={<SupportOutlined fontSize='large' />} 
                                subTitle={t('home_8')} content={t('home_9')}/>
                    </div>
                </div>
                {/* FAQ Container */}
                <a id="faq">
                    <FAQContainer backgroundType='secondary'/>
                </a>
                <NewUserPromotionModal />
            </div>
        </>
    );
};

export default MainHero;
