import { useCallback, useEffect, useRef, useState } from "react"

let touching = false;
let startX = 0;

export default function MobileSwiper({ children }) {
    const wrapperRef = useRef(null);
    // const [startX, setStartX] = useState(0);
 
    const disableScroll = (e) => {
        e.preventDefault();
        e.stopPropagation();
        return false;
    };

    const disableHorizontalScroll = (e) => {
        let xDiff = Math.abs(startX - e.touches[0].clientX);

        if (xDiff > 5) {
            disableScroll(e);
        }
    };

    const handleTouchStart = (e) => {
        startX = e.touches[0].clientX;
    }

    const mouseUp = () => {
        touching = false;
    }

    useEffect(() => {
        // Disable horizontal scroll on touch events
        window.addEventListener("touchstart", handleTouchStart, { passive: false })
        window.addEventListener("touchend", mouseUp, { passive: false })
        document.addEventListener('touchmove', disableHorizontalScroll, { passive: false });

        return () => {
            // Cleanup to re-enable scrolling
            document.removeEventListener('touchmove', disableHorizontalScroll);
        };
    }, []);
    
    return <div ref={wrapperRef}>{children}</div>;
}