export function mainFormSearch() {
    return {
        filters : {
            model : '',
            make : '',
            vehicleType : '',
            registrationFrom : '',
            registrationTo : '',
            priceFrom : '',
            priceTo : '',
            mileageFrom : '',
            mileageTo : '',
            powerFrom : '',
            powerTo : '',
            fuel : '',
            transmission : '',
            color : '',
            version : [],
            engine : [],
        }
    }
}