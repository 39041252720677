import './index.scss';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../route';
import UrlNavigatorSingleton from '../../lib/UrlNavigator';
import store from '../../redux/store';
import { setApp } from '../../redux/actions/app';
import { useTranslation } from 'react-i18next';

const LeftBar = (props) => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    if(!props.user.id) return null;
    if(!props.app.branding && props.user.apps){
        store.dispatch(setApp(props.user.apps[0]));
        return null;
    }

    const findRoutePathByKey = (key) => {
        // Function to recursively search for a route key in nested routes
        const recursiveFind = (routeArray, key) => {
            for (const route of routeArray) {
                if (route.key === key) {
                    return route.path;
                }
                if (route.children) {
                    const foundPath = recursiveFind(route.children, key);
                    if (foundPath) return foundPath;
                }
            }
        };
        return recursiveFind(ROUTES, key);
    };

    const renderTitle = ({icon, breadcrumb, key, active}) => {
        const path = findRoutePathByKey(key);
        const isActive = UrlNavigatorSingleton.isInPage(key);

        const title = t(breadcrumb);
        if(title.length == 0)
            title = breadcrumb;
        if(!active){return null;}
        return (
            <div className='nav-link'>
                <Link  
                    to={path}
                    className={`title-leftBar left-bar-icon ${isActive ? "isActive" : ""}`}>
                    {icon}
                    <h5 className='text-left left-bar-text title'>{title}</h5>
                </Link>
            </div>
        );
    };

    return (
        <div className='left-bar'>
            <div className="align-center">
                <div className='container-box-left-bar'>
                    {ROUTES.map(route => {
                        if (route.children) {
                            return (
                                <div key={route.key}>
                                    {route.children.map(subRoute => (
                                        <div key={subRoute.key}>
                                            {renderTitle(subRoute)}
                                        </div>
                                    ))}
                                </div>
                            );
                        }
                    })}
                </div>
            </div>
        </div>
    );
};

function mapStateToProps(state) {
    return {
        user: state.user,
        router: state.router,
        app : state.app
    };
};

export default connect(mapStateToProps)(LeftBar);
