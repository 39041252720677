import React, {useCallback} from 'react'
import './index.scss';
import { UploadFileOutlined } from '@mui/icons-material';
import { useDropzone } from 'react-dropzone'

/* Upload Area Component */
/* Save only the first file */
/* Send 64 bit encoded string to the server */
const UploadArea = (props) => {
    const {type, form, title, onChange, keyItem, parentField} = props;

    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
        const reader = new FileReader()

        reader.onabort = () => console.log('file reading was aborted')
        reader.onerror = () => console.log('file reading has failed')
        reader.onload = () => {
            try{
            // Do whatever you want with the file contents
                const binaryStr = reader.result;
                /* Convert to 64 bit encoded string */
                const base64 = btoa(
                    new Uint8Array(binaryStr)
                    .reduce((data, byte) => data + String.fromCharCode(byte), '')
                );
                /* add base 64 to header to work in img src */
                const base64Header = `data:image/png;base64,${base64}`;
                /* Send to props */
                return onChange(keyItem, base64Header, parentField, {variable : true, array : false});
            }catch(e){
                
            }
        }
        reader.readAsArrayBuffer(file)
        })
        
    }, [])

    const {getRootProps, getInputProps} = useDropzone({onDrop})
    const hasImage = form[parentField][keyItem] != '';

    return (
        <div className='input-area' {...getRootProps()}>
            {hasImage ? 
                <img src={form[parentField][keyItem]} alt='img'/> : 
            <>
                <input {...getInputProps()} />
                <div className='middle'>
                    <div>
                        <UploadFileOutlined/>
                        <p>{title}</p>
                    </div>
                </div>
            </>
               
            }

        </div>
    );
}


export default UploadArea;