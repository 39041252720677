/* Create Brand Picker Modal */
/* Should be able to select a brand from a list of brands */
/* Should be able to search for a brand */
/* Should be able to create a new brand */

import { connect } from "react-redux";
import "./index.scss";
import { withTranslation } from "react-i18next";
import { isValidEmail, renderInput, renderItemList } from "../../lib/Helpers";
import { Component } from "react";
import { filtersSearch, getFilterValues } from "../../../filters/search";
import { Button } from "@mui/material";
import APIConnection from "../../api"
import ContainedLoading from "../ContainedLoading";
import { withNavigate } from "../../../utils/navigator";
import { isMobile } from "../../../utils/utils";

const mandatoryKeys = [
    'make',
    'model',
    'registrationFrom',
    'fuel',
    'mileageTo',
    'email',
    'phoneNumber',
    'budget' 
]
const formDefault = {
    'make': '',
    'model': '',
    'registrationFrom': '',
    'fuel': '',
    'mileageTo': '',
    'email': '',
    'phoneNumber': '',
    'budget': '',
    'otherRequests': '',
}
class CarRequestForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            form: formDefault,
            disableButton:true,
            allFilters: null,
        }
    }

    componentDidMount = async () => {
        const { t, i18n, user } = this.props;
        const filters = await filtersSearch(t,i18n.language,this.state.form.make);
        
        const aux = this.state.form;
        if(user.email)
            aux.email = user.email;
        if(user.phone_number)
            aux.phone_number = user.phone_number;

        this.setState({allFilters: filters, form: aux});
    }

    emptyDropDown = (subType) => {
        const aux = this.state.form;
        aux[subType] = '';
        this.setState({form: aux});
    };

    onChange = (key, value, parentField, options={variable : false, array : false}) => {
        this.setState(prevState => ({
            form: {
                ...this.state.form,
                [key]: value
            }
        }),() => {
            let disable = false;
            for(let i = 0; i < mandatoryKeys.length; i++){
                if(this.state.form[mandatoryKeys[i]] === null || this.state.form[mandatoryKeys[i]] === undefined 
                    || this.state.form[mandatoryKeys[i]] == ''){
                    disable = true;
                    break;
                }
            }
            
            if(!disable)
                disable = !isValidEmail(this.state.form['email']);
            
            if(this.state.disableButton != disable){
                this.setState({disableButton: disable});
            }
        });
    }

    sendRequest = async () => {
        const { t, i18n } = this.props;
        const aux = this.state.form;
        this.setState({disableButton: true});
        //we only need to get the value of the fuel, so make after that the other values (make, model or more) are still normal
        const form = await getFilterValues(t,i18n.language,this.state.form);
        form.make = aux.make;
        form.model = aux.model;
        form.other_requests = aux.other_requests;
        let response = await APIConnection.createRequest({
            form
        });

        if(response.status !== 200){
            alert(t('request_4'));
            this.setState({disableButton: false});
        }else{
            // if(window.location.pathname.slice(1) == 'request-form'){
                this.props.navigate('/');
            //     return;
            // }
            // window.location.reload();
        }
    }

    render = () => {
        const { user, t, fullPage } = this.props;

        const mobile = isMobile(window);
        return (
            <div className="request-form-container">
                <div className="flex flex-col !items-start">
                {
                        this.state.allFilters == null ?
                        <ContainedLoading/>
                        :
                        <div className="w-full">
                            <div className="center">
                                <h2>
                                    {t('request_1')}
                                </h2>
                            </div>
                            <div className={`${mobile ? '' : 'grid-33'}`}>
                                {renderInput({title : t('make'), keyItem : 'make', placeholder : t('make'),
                                    form:this.state.form,
                                    onChange: this.onChange,
                                    isRequired: true
                                })}
                                {renderInput({title : t('model'), keyItem : 'model', placeholder : t('model'),
                                    form:this.state.form,
                                    onChange: this.onChange,
                                    isRequired: true
                                })}
                                {renderItemList({title : t('year'), keyItem : 'registrationFrom', placeholder : t('year'),
                                    allFilters: this.state.allFilters,emptyDropDown: this.emptyDropDown,form:this.state.form,onChange: this.onChange
                                    ,search: true,id: 3,allowNewOption:true,isRequired: true
                                })}
                            </div>
                            <div className={`${mobile ? '' : 'grid-33'}`}>
                                {renderItemList({title : t('fuel'), keyItem : 'fuel', placeholder : t('fuel'),
                                    allFilters: this.state.allFilters,emptyDropDown: this.emptyDropDown,form:this.state.form,onChange: this.onChange,
                                    id: 3,isRequired: true
                                })}
                                {renderInput({type:'number',title : t('request_3'), keyItem : 'mileageTo', placeholder : t('mileage'),
                                    form:this.state.form,onChange: this.onChange,isRequired: true
                                })}
                                {renderInput({type:'number',title : t('budget'), keyItem : 'budget', placeholder : t('budget'),
                                    form:this.state.form,onChange: this.onChange,isRequired: true
                                })}
                            </div>
                            <div className={`${mobile ? '' : 'grid-50 width98'}`}>
                                {renderInput({type:'email',title : t('email'), keyItem : 'email', placeholder : t('email'),
                                    form:this.state.form,onChange: this.onChange,isRequired: true,
                                    isValid:isValidEmail(this.state.form['email']), error:t('signup_3')
                                })}
                                {renderInput({type:'tel',title : t('phone_number'), keyItem : 'phoneNumber', placeholder : t('phone_number'),
                                    form:this.state.form,onChange: this.onChange,isRequired: true
                                })}
                            </div>
                            <div className={`${mobile ? '' : 'width100-with-gap-space'}`}>
                                {renderInput({type:'text',title : t('other_requests'), keyItem : 'otherRequests', placeholder : t('other_requests'),
                                    form:this.state.form,onChange: this.onChange, textArea:true,cols:30, rows:3
                                })}
                            </div>
                            <Button className={`button ${this.state.disableButton ? 'btn-disabled' : ''} fit ml-5`} onClick={() => this.sendRequest()}>
                                <h6>{t('request_2')}</h6>
                            </Button>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user : state.user,
    };
}

export default connect(mapStateToProps)(withTranslation()(withNavigate(CarRequestForm)));