import React, { useState, useEffect } from 'react';
import { defaultCar } from '../../../assets/images';
import { Carousel } from 'react-responsive-carousel';

const ImageDisplayer = ({ imageUrls, imageClasses, imageOnClick = () => {}, showThumbs=false, showStatus=false, showArrows=false,showIndicators=false }) => {
  const [images, setImages] = useState([]);
  const [firstImage, setFirstImage] = useState(false);

  useEffect(() => {
    setImages([]); // Reset images immediately on URL change
    const loadImages = async () => {

      const firstImageLoaded = await new Promise((resolve) => {
        const img = new Image();
        const url = imageUrls[0];
        img.src = url;
        img.onload = () => resolve(url);
        img.onerror = () => resolve(defaultCar);
      });

      setImages([firstImageLoaded]);
      setFirstImage(true);
    };

    loadImages();
  }, [imageUrls]);

  useEffect(() => {
    const loadImages = async () => {
      const loadedImages = await Promise.all(
        imageUrls.slice(1,imageUrls.length).map((url) => 
          new Promise((resolve) => {
            const img = new Image();
            img.src = url;
            img.onload = () => resolve(url);
            img.onerror = () => resolve(defaultCar);
          })
        )
      );
      loadedImages.unshift(images[0]);
      setImages(loadedImages);
    }
    if(firstImage){
      loadImages();
      setFirstImage(false);
    }
  },[firstImage]);

  if (images.length == 0) {
    // Render shimmer while images are loading
    return (
      <div className="carousel">
        <div className="shimmer" style={{ height: "300px", width: "100%" }}></div>
      </div>
    );
  }

  if(imageUrls.length > 1)
    return (
      <Carousel className='carousel' showArrows={showArrows} showStatus={showStatus} showThumbs={showThumbs} showIndicators={showIndicators}
      swipeable={true} 
      emulateTouch={true} 
      preventMovementUntilSwipeScrollTolerance={true}>
        {images.map((src, index) => (
          <div onClick={imageOnClick}>
            <img key={src} src={src} alt={`Imagem de carro importado da europa ${index}`} className={imageClasses}/>
          </div>
        ))}
      </Carousel>
    );
  return <img key={images[0]} src={images[0]} alt={`Imagem de carro importado da europa`} className={imageClasses} />;
};

export default ImageDisplayer;
