import './index.scss';
import { Component } from 'react';
import { connect } from 'react-redux';
import { MetaTags } from 'react-meta-tags';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { withTranslation } from 'react-i18next';
import { isMobile } from '../../../utils/utils';
import FindYourCarHero from '../../sections/FindYourCarHero';

class FindYourCarPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            useForm: true
        };
    }

    render() {
        const mobile = isMobile(window);
        return (
            <>
                {/* {loading && <Loading />} */}
                <MetaTags>
                    <title> Versicar </title>
                    <meta name="description" content="Buy your best car" />
                    <meta property="og:title" content="Versicar" />
                </MetaTags>
                <Header />
                <FindYourCarHero/>
                <Footer />
            </>
        );
    }
}

function mapStateToProps(state) {
    const user = state.user;
    return { user };
}

export default connect(mapStateToProps)(withTranslation()(FindYourCarPage));