/* Create FAQ Container with Questions and Answers */
import React from 'react';
import './index.scss';
import Button from '../../components/Button';
import InformationCard from '../../components/InformationCard'
import { useTranslation } from 'react-i18next';
import { isMobile } from '../../../utils/utils';

//the background type must be a css class defined in the themes. only the name is necessary
//that is, if the class is called primary-background you only need to pass primary
//if no background type is passed the primary-background will be used as default
const HowItWorksContainer = ({backgroundType, hideKnowMore = false}) => {
    if(!backgroundType){
        backgroundType = 'primary'
    };
    backgroundType += '-background';
    const { t, i18n } = useTranslation();

    const faq = [
        {
            question : t('work_24'),
            answer : t('work_25')
        },
        {
            question : t('work_26'),
            answer : t('work_27')
        },
        {
            question : t('work_28'),
            answer : t('work_29')
        },
    ]

    const mobile = isMobile(window);
    return (
        <div className={`section ${backgroundType}`}>
            <h2 className='center small-container'> {t('work_31')} </h2>
            <div className='container how-it-works-container'>
                <div className={mobile ? '' : 'grid-33'}>
                    {faq.map((item, index) => {
                        return <InformationCard key={index} 
                        title={index + 1} subTitle={item.question} content={item.answer}/>
                    })}
                </div>
                <div className='container center'> 
                    {hideKnowMore ? <></> : 
                    <Button className='button fit '>
                        <h4>{t('work_30')}</h4>
                    </Button>
                    }
                </div>
            </div>
        </div>
    )
}

export default HowItWorksContainer;