import React, { Component } from 'react';
import './index.scss'
import { CloseOutlined } from '@mui/icons-material';
import Button from '../Button';


class Modal extends Component{
    constructor(props){
        super(props)
        this.state = {
            isOpen : false    
        }
        this.ref = React.createRef();
    }

    onFocusLost = (e) => {
        if (!this.ref.current.contains(e.target)) {
            this.closeModal();
        }
    };

    open = () => {
        this.setState({isOpen : true});
        if(document.getElementById(`modal-${this.props.keyItem}`)){
            document.getElementById(`modal-${this.props.keyItem}`).classList.remove('hideModal');
        }
    }

    closeModal = () => {
        if(this.props.onClose)
            this.props.onClose();
        this.setState({isOpen : false});
        if(document.getElementById(`modal-${this.props.keyItem}`)){
            document.getElementById(`modal-${this.props.keyItem}`).classList.add("hideModal");
        }
    }

    renderMiddleModal = () => {
        const { keyItem, children } = this.props;

        return (
            <div id={`modal-${keyItem}`} key={`modal-${keyItem}`} className='hideModal'>
                <div className='background-modal'/>
			    <div ref={this.ref} className='modal-middle modal-box'>
                    <div className='stack'>
                        
                            <button className='closeIcon hover:cursor-pointer' onClick={() => this.closeModal()}>
                                <CloseOutlined/>
                            </button>    
                        
                        {children}
                    </div>
                   
                </div>
            </div>
			
        )
    }

    componentDidMount = () => {
        this.open();
        document.addEventListener("mousedown", this.onFocusLost);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.onFocusLost);
    }

    render = () => {
        const { keyItem, children, middle } = this.props; 
        if(middle){return this.renderMiddleModal()}
        else{
            return (
                <div ref={this.ref} id={`modal-${keyItem}`} key={`modal-${keyItem}`} className='modal modal-box hideModal'>
                    {children}
                    <Button className='button box-background' onClick={() => this.closeModal()}><h5>Close</h5></Button>
                </div>
            )
        }
    }
    
}

export default Modal;
