import { loadingIcon } from '../../../assets/images';
import { isMobile } from '../../../utils/utils';
import './index.scss';
import { connect } from "react-redux";

const Loading = (props) => {

    const mobile = isMobile(window);
    return (
        <div className="loading-container">
            <img src={loadingIcon}/>
        </div>
    )  
}

function mapStateToProps(state) {
    return {
        user :state.user
    };
}

export default connect(mapStateToProps)(Loading);