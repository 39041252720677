import './index.scss';
import { Component } from 'react';
import { Pagination } from '@mui/material';
import { withTranslation } from 'react-i18next';
import { isMobile } from '../../../utils/utils.js';

class PaginationContainer extends Component {
    handlePageChange = (event, value) => {
        const { onChangePage } = this.props;
        onChangePage(event, value);
    };

    render() {
        let { page, pages, t } = this.props;
        const isFirstPage = page === 1;
        const isLastPage = page === pages;
        const mobile = isMobile(window);

        if(pages === 0)
            page = 0;
        return (
            <div className={`${mobile ? 'block' : 'grid-pagination grid'}`}>
                <div className='left center'>
                    <h5><span className='grey inline'>{t('pagination_1')}</span> {page} <span className='grey inline'>{t('pagination_2')}</span> {pages}</h5>
                </div>
                <div className='pagination'>
                    <Pagination
                        page={page}
                        onChange={this.handlePageChange}
                        count={pages}
                        shape="rounded"
                        disabled={pages <= 1}
                    />
                </div>
                <div></div>
            </div>
        );
    }
}

export default (withTranslation()(PaginationContainer));