import { Component } from 'react';
import './index.scss';


export default class Button extends Component{
    constructor(props){super(props)}

    render = () => {
        return (
            <button {...this.props} onClick={this.props.onClick}>
                {this.props.children}
            </button> 
        )
    }
}
    