import './index.scss';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import store from '../../redux/store';
import { ErrorOutline, OutboundOutlined } from '@mui/icons-material';
import { setNotifcation } from '../../redux/actions/notification';


const NotificationTab = (props) => {
    const { notification } = props;
    const [notifications, setNotifcations] = useState([]);

    useEffect(() => {
        if(!notification.show){return /* Dont Show Error */}
        // Function to check and add new items to notifications state
        const handleNewList = async () => {
            // Check if the item is not already in notifications state
            if (!notifications.some(existingItem => existingItem.code === notification.code)) {
                notification.show = true;
                // Add the item to notifications state
                setNotifcations(prevArray => [...prevArray, notification]);
                // Set a timeout to remove the item from notifications state after 2 seconds
                setTimeout(async () => {
                    setNotifcations(prevArray =>
                        prevArray.map(a => 
                            (a.code == notification.code) ? {...a, show : false} : a)
                    );
                }, 2000);
                // Set a timeout to remove the item from notifications state after 2 seconds
                setTimeout(async () => {
                    await store.dispatch(setNotifcation({}))
                    setNotifcations(prevArray =>
                        prevArray.filter(a => a.code !== notification.code)
                    );
                }, 3000);
            }
        };
        if(notification.code){
            // Call the function when newList prop changes
            handleNewList();
        }
    }, [notification]);

    const renderTab = ({item, key}) => {
        let isActive = item.show;
        const isError = item.code != 200;
        return (
            <div key={key} className={`${ isActive ? "show" : "hide"} error-tab box`}>
                <div className='flex-container'>
                    {isError ? <ErrorOutline/> : <OutboundOutlined/>}
                    <h5 className='grey'>{isError ? 'Error' : 'Notification'}</h5>
                </div>
                <div className='content'>
                    <h5> {item.type} </h5>
                    <h5 className='grey'> {item.message} </h5>
                </div>
            </div>
        )
    }
    return (
        <div>
            {notifications.map((item, index) => (
                renderTab({item, key : index})
            ))}
        </div>
    );
}


function mapStateToProps(state) {
    return {
        notification : state.notification
    };
  }

 export default connect(mapStateToProps)(NotificationTab);
