import { Component } from 'react';
import {URL, axiosInstance} from './utils'; // Ensure this points to the correct location of your axiosInstance setup

class Connection extends Component {
    constructor(props) {
        super(props);
    }

    // Process the response to return only the required data
    handleResponse = (response) => {
        return response.data;
    }

    // Centralize error handling and potentially throw to be caught by the caller
    handleError = (error) => {
        console.error('API call failed:', error);
        // Log more error details, extract and reformat if necessary
        throw (error.response && error.response.data && error.response.data.error) ? 
              new Error(error.response.data.error) : 
              new Error('An unknown error occurred');
    }

    register = async ({ username, password, email, name, promotion }) => {
        try {
            const response = await axiosInstance.post('/users/register', { username, password, email, name, promotion });
            return this.handleResponse(response);
        } catch (error) {       
            console.log(error)
            return this.handleError(error);
        }
    }

    login = async ({ email, password }) => {
        try {
            const response = await axiosInstance.post('/users/login', { password, email });
            return this.handleResponse(response);
        } catch (error) {
            return this.handleError(error);
        }
    }

    googleLogin = async ({ googleBearerToken,promotion }) => {
        try {
            const response = await axiosInstance.post('/users/googleAuth', { googleBearerToken,promotion });
            return this.handleResponse(response);
        } catch (error) {
            return this.handleError(error);
        }
    }

    auth = async () => {
        try {
            const response = await axiosInstance.post('/users/auth');
            return this.handleResponse(response);
        } catch (error) {
            return this.handleError(error);
        }
    }

    requestResetPassword = async ({ email }) => {
        try {
            const response = await axiosInstance.post('/users/password/request', { email });
            return this.handleResponse(response);
        } catch (error) {
            return this.handleError(error);
        }
    }

    resetPassword = async ({ token, password, email }) => {
        try {
            const response = await axiosInstance.post('/users/password/reset', { token, password, email });
            return this.handleResponse(response);
        } catch (error) {
            return this.handleError(error);
        }
    }

    registerCar = async ({ car }) => {
        try {
            const response = await axiosInstance.post('/cars/register', { car });
            return this.handleResponse(response);
        } catch (error) {
            return this.handleError(error);
        }
    }
    
    searchCars = async ({ filters }) => {
        try {
            const response = await axiosInstance.post('/cars/search', { filters });
            return this.handleResponse(response);
        } catch (error) {
            return this.handleError(error);
        }
    }

    getCar = async ({ id }) => {
        try {
            const response = await axiosInstance.post('/cars/get', { id });
            return this.handleResponse(response);
        } catch (error) {
            return this.handleError(error);
        }
    }

    getNumberOfOffers = async (filters = {}) => {
        try {
            const response = await axiosInstance.post('/cars/getNumberOfOffers', { filters });
            return this.handleResponse(response);
        } catch (error) {
            return this.handleError(error);
        }
    }

    getMakes = async () => {
        try {
            const response = await axiosInstance.get('/cars/getMakes');
            return this.handleResponse(response);
        } catch (error) {
            return this.handleError(error);
        }
    }

    getExistingMakes = async () => {
        try {
            const response = await axiosInstance.get('/cars/getExistingMakes');
            return this.handleResponse(response);
        } catch (error) {
            return this.handleError(error);
        }
    }

    getModels = async () => {
        try {
            const response = await axiosInstance.get('/cars/getModels');
            return this.handleResponse(response);
        } catch (error) {
            return this.handleError(error);
        }
    }

    getExistingMakesAndModels = async () => {
        try {
            const response = await axiosInstance.get('/cars/getExistingMakesAndModels');
            return this.handleResponse(response);
        } catch (error) {
            return this.handleError(error);
        }
    }

    getExistingModels = async () => {
        try {
            const response = await axiosInstance.get('/cars/getExistingModels');
            return this.handleResponse(response);
        } catch (error) {
            return this.handleError(error);
        }
    }

    getFuelTypes = async () => {
        try {
            const response = await axiosInstance.get('/cars/getFuelTypes');
            return this.handleResponse(response);
        } catch (error) {
            return this.handleError(error);
        }
    }

    getTransmissionTypes = async () => {
        try {
            const response = await axiosInstance.get('/cars/getTransmissionTypes');
            return this.handleResponse(response);
        } catch (error) {
            return this.handleError(error);
        }
    }

    addFavorite = async ({ id }) => {
        try {
            const response = await axiosInstance.post('/cars/favorite', { id });
            return this.handleResponse(response);
        } catch (error) {
            return this.handleError(error);
        }
    }

    getPromotedCars = async () => {
        try {
            const response = await axiosInstance.get('/cars/getPromotedCars');
            return this.handleResponse(response);
        } catch (error) {
            return this.handleError(error);
        }
    }

    getFavorites = async () => {
        try {
            const response = await axiosInstance.post('/cars/favorites');
            return this.handleResponse(response);
        } catch (error) {
            return this.handleError(error);
        }
    }

    createRequest = async ({ form }) => {
        try {
            const response = await axiosInstance.post('/cars/createRequest', { form });
            return this.handleResponse(response);
        } catch (error) {
            return this.handleError(error);
        }
    }

    createRequestFind = async ({ form }) => {
        try {
            const response = await axiosInstance.post('/cars/createRequestFind', { form });
            return this.handleResponse(response);
        } catch (error) {
            return this.handleError(error);
        }
    }

    myCarDetails = async ({ id }) => {
        try {
            const response = await axiosInstance.post('/user/car/details', { id });
            return this.handleResponse(response);
        } catch (error) {
            return this.handleError(error);
        }
    }
    
    createOrder = async ({ form }) => {
        try {
             const response = await axiosInstance.post('/orders/create', { form });
            return this.handleResponse(response);
        } catch (error) {
            return this.handleError(error);
        }
    }

    getOrders = async () => {
        try {
            const response = await axiosInstance.post('/orders/get');
            return this.handleResponse(response);
        } catch (error) {
            return this.handleError(error);
        }
    }

    getGoogleReviews = async () => {
        try {
            const response = await axiosInstance.get('/googleReviews');
            return this.handleResponse(response);
        } catch (error) {
            return this.handleError(error);
        }
    }
};

export default new Connection();  // Exporting an instance to be reused across the app
