/* Create Hero Section with Image and Text */
import './index.scss';
import React, {useState } from 'react';
import { useTranslation } from 'react-i18next';
import CarRequestForm from '../../components/CarRequestForm';
import CarFindForm from '../../components/CarFindForm';
import SelectCard from '../../components/Card/Select';
import { carIcon, linkIcon } from '../../../assets/images';

const FindYourCarHero = () => {
    const { t, i18n } = useTranslation();
    const [useForm, setUseForm] = useState(true);

    const changeDisplay = (isForm) => {
        if(useForm != isForm){
            setUseForm(isForm)
        }
    }

    return (
        <div className='find-your-car-container section center'>
            <div className='content'>
                <div className='grid-50 w-full mb-5'>
                    {/* <SelectCard icon={carIcon} text={t('find_your_car_3')} isSelected={useForm} onClick={() => changeDisplay(true)}/>
                    <SelectCard icon={linkIcon} text={t('find_your_car_4')} isSelected={!useForm} onClick={() => changeDisplay(false)}/> */}
                </div>
                {
                    useForm ?
                    <CarRequestForm/>
                    :
                    <CarFindForm/>
                }
            </div>
        </div>
    );
};

export default FindYourCarHero;
