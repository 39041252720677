import { Component } from 'react';
import './index.scss';
import Footer from "../../../components/Footer";
import { MetaTags } from 'react-meta-tags';
import { withTranslation } from 'react-i18next';
import Header from '../../../components/Header';

class TermsAndConditions extends Component{

    constructor(props){
        super(props);
        this.state = {packs : []}
    }

    componentDidMount(){

    }

    renderTermItem(index, title, content, inline = false, extra = null){
        if(inline)
            return (
                <div className='block'>
                    <h4 className='text-grey inline'>{index}. </h4> 
                    {
                        title ?
                        <h4 className='inline font-extrabold text-black'>{title}: </h4> 
                        :
                        <></>
                    }
                    
                    <h4 className='text-grey inline'>
                        {content}
                    </h4>
                    {extra ? extra : <></>}
                    </div>
            );
        return (
            <div className='block'>
                <h3 className='text-grey'>
                    {index}. {title}
                </h3>
                <h4 className='text-grey'>
                    {content}
                </h4>
                {extra ? extra : <></>}
            </div> 
        );
    }

    renderTermItemList(index, title, content){
        let i = 1;
        return (
            <>
            <h3 className='text-grey'>
                {index}. {title}
            </h3>
            {content.map((item, idx) => 
            {   
                const t = item[0];
                const s = item[1];
                return this.renderTermItem(index + "." + i++,t,s,true);
            })}
            </> 
        );
    }

    render = () => {
        const { t } = this.props;
        let i = 1;
        return (
            <div className='policies-container'>   
                 <MetaTags>
                    <title>{t('terms_1')}</title>
                    <meta name="description" content="Terms & Services" />
                    <meta property="og:title" content="Vertate" />
                </MetaTags>
                <Header/>
                <div className='text-container'>
                    <h2 className='text-primary text-center'>{t('terms_1')}</h2>
                    {this.renderTermItem(i++,t('terms_2'),t('terms_2_1'))}
                    {this.renderTermItemList(i++,t('terms_3'),
                        [
                            [t('terms_3_1'),t('terms_3_2')],
                            [t('terms_3_3'),t('terms_3_4')],
                            [t('terms_3_5'),t('terms_3_6')],
                            [t('terms_3_7'),t('terms_3_8')],
                            [t('terms_3_9'),t('terms_3_10')],
                            [t('terms_3_11'),t('terms_3_12')],
                            [t('terms_3_13'),t('terms_3_14')],
                        ]
                    )}
                    {this.renderTermItem(i++,t('terms_4'),t('terms_4_1'))}
                    {this.renderTermItemList(i++,t('terms_5'),
                        [
                            [t('terms_5_1'),t('terms_5_2')],
                            [t('terms_5_3'),t('terms_5_4')],
                            [t('terms_5_5'),t('terms_5_6')],
                            [t('terms_5_7'),t('terms_5_8')],
                            [t('terms_5_9'),t('terms_5_10')],
                        ]
                    )}
                    {this.renderTermItemList(i++,t('terms_6'),
                        [
                            [null,t('terms_6_2')],
                            [null,t('terms_6_2')],
                        ]
                    )}
                    {this.renderTermItemList(i++,t('terms_7'),
                        [
                            [t('terms_7_1'),t('terms_7_2')],
                            [t('terms_7_3'),t('terms_7_4')],
                            [t('terms_7_5'),t('terms_7_6')],
                        ]
                    )}
                    {this.renderTermItemList(i++,t('terms_8'),
                        [
                            [t('terms_8_1'),t('terms_8_2')],
                            [t('terms_8_3'),t('terms_8_4')],
                        ]
                    )}
                    {this.renderTermItem(i++,t('terms_9'),t('terms_9_1'))}
                    {this.renderTermItem(i++,t('terms_10'),t('terms_10_1'))}
                    {this.renderTermItem(i++,t('terms_11'),t('terms_11_1'),false,
                        <>
                            <ul>
                                <li>
                                    <h4 className='text-black inline font-extrabold'>
                                        {t('terms_11_2')}:
                                    </h4>
                                    <h4 className='text-grey inline'>
                                        {t('terms_11_3')}
                                    </h4>
                                </li>
                                <li>
                                    <h4 className='text-black inline font-extrabold'>
                                        {t('terms_11_4')}:
                                    </h4>
                                    <h4 className='text-grey inline'>
                                        {t('terms_11_5')}
                                    </h4>
                                </li>
                            </ul>
                        </>
                    )}

                </div>
                <Footer width={100} full={true}/>
            </div>
            )
        }
}

export default (withTranslation()(TermsAndConditions));